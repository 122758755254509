import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { AutoSizer, MultiGrid } from 'react-virtualized';

import apiClient from '../../api-client';

const defaultStyles = {
  subtitle: {
    fontSize: '1.3em',
    margin: '15px'
  },
  gridCell: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    flexWrap: 'wrap',
    border: '1px solid #e50140'
  },
  tableBackground: {
    marginTop: '15px',
    width: '100%',
    height: '82vh'
  },
  evenRow: {
    backgroundColor: 'rgba(167,4,46,0.3)'
  },
  oddRow: {
    backgroundColor: 'rgba(167,4,46,0.1)'
  }
};

const isEven = num => !(num % 2);

const getRowClassName = (classes, row) => {
  const rowClass = isEven(row) ? classes.evenRow : classes.oddRow;
  return `${classes.gridCell} ${rowClass}`;
};

const createBodyRenderer = (data, classes) => ({
  columnIndex,
  key,
  rowIndex,
  style
}) => (
  <div key={key} className={getRowClassName(classes, rowIndex)} style={style}>
    {data[rowIndex][columnIndex]}
  </div>
);

const createHeaderRenderer = (data, classes) => {
  const headers = {
    id: 'Publisher',
    units: 'Taxonomy',
    bidding: 'Bidding',
    targeting: 'Targeting',
    tracking: 'Tracking'
  };
  const offsets = {
    bidding: 7 * 30,
    targeting: 16 * 30,
    tracking: 37 * 30,
    rest: 60 * 30
  };

  return ({ columnIndex, key, rowIndex, style }) => {
    const field = data[rowIndex][columnIndex];
    let width = style.width;
    let left = style.left;
    if (columnIndex === 2) {
      width = width * 3;
      left = offsets.bidding;
    }
    if (columnIndex === 3) {
      width = width * 7;
      left = offsets.targeting;
    }
    if (columnIndex === 4) {
      width = width * 6;
      left = offsets.tracking;
    }
    if (columnIndex > 5) {
      width = 0;
      left = offsets.rest;
    }
    return (
      <div
        key={key}
        className={getRowClassName(classes, rowIndex)}
        style={{ ...style, width, left }}
      >
        {headers[field]}
      </div>
    );
  };
};

const cellRenderer = (data, { classes }) => {
  const headerRenderer = createHeaderRenderer(data, classes);
  const bodyRenderer = createBodyRenderer(data, classes);

  return ({ columnIndex, key, rowIndex, style }) =>
    rowIndex
      ? bodyRenderer({ columnIndex, key, rowIndex, style })
      : headerRenderer({ columnIndex, key, rowIndex, style });
};

const calculateColumnWidth = scope => {
  const widths = { id: 180, taxonomy: 210, rest: 90 };
  return ({ index }) => {
    if (index === 0) return widths.id;
    if (index === 1) return widths.taxonomy;
    return widths.rest;
  };
};

const MuiVirtualizedGrid = props => {
  const { classes, rowHeight, headerHeight, gridData, ...tableProps } = props;
  return (
    <AutoSizer>
      {({ height, width }) => (
        <MultiGrid
          {...tableProps}
          height={height}
          width={width}
          columnWidth={calculateColumnWidth()}
          rowHeight={rowHeight}
          cellRenderer={cellRenderer(gridData, props)}
          fixedColumnCount={1}
          fixedRowCount={2}
        ></MultiGrid>
      )}
    </AutoSizer>
  );
};

MuiVirtualizedGrid.defaultProps = {
  headerHeight: 48,
  rowHeight: 48
};

const VirtualizedGrid = withStyles(defaultStyles)(MuiVirtualizedGrid);

const parseDataValue = (property, header) =>
  Object.entries(property).reduce((acc, [key, val]) => {
    let replacement = val;
    if (val === true) replacement = '✅';
    if (val === false) replacement = '❌';
    acc[key] = replacement;
    return acc;
  }, {});

const SitescriptOverview = props => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    apiClient.sitescripts
      .overview({ signal })
      .then(setData)
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      });

    return () => {
      abortController.abort();
    };
  }, []);

  const formatted = data.map(
    ({ id, taxonomy, bidding, targeting, tracking }) => {
      // rename theAdex in tracking to avoid conflict
      if (Object.prototype.hasOwnProperty.call(tracking, 'theAdex'))
        tracking['theAdex (tracking)'] = tracking.theAdex;

      return {
        id,
        units: taxonomy.filter(unit => unit !== 'other').join(', '),
        ...parseDataValue(bidding),
        ...parseDataValue(targeting),
        ...parseDataValue(tracking)
      };
    }
  );
  const columns = [...new Set(formatted.flatMap(Object.keys))];
  const headers = ['id', 'units', 'bidding', 'targeting', 'tracking'];
  const rows = formatted.map(row => columns.map(key => row[key] || ''));

  const gridData = [headers, columns, ...rows];

  const { classes } = props;

  return (
    <>
      <Typography className={classes.subtitle}>Sitescript: Overview</Typography>
      {!!data.length && (
        <Paper className={classes.tableBackground}>
          <VirtualizedGrid
            rowCount={gridData.length}
            gridData={gridData}
            columnCount={columns.length}
          />
        </Paper>
      )}
    </>
  );
};

export default withStyles(defaultStyles)(SitescriptOverview);
