import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DetailsIcon from '@material-ui/icons/Details';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import CalendarToday from '@material-ui/icons/CalendarToday';

const styles = {
  container: {
    padding: 16
  },
  changesContainer: {
    color: 'white'
  },
  logRow: {
    height: '40px'
  },
  buttonCreate: {
    float: 'right'
  }
};

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsDialog: false,
      current: {}
    };
  }

  onDetailClick(log) {
    this.setState({
      detailsDialog: true,
      current: log
    });
  }

  onCloseDialog() {
    this.setState({
      detailsDialog: false,
      current: {}
    });
  }

  renderKind(kind) {
    let text, color;
    switch (kind) {
      case 'N':
      case 'create':
        text = 'Added';
        color = 'green';
        break;
      case 'D':
      case 'delete':
        text = 'Deleted';
        color = 'red';
        break;
      case 'E':
      case 'update':
        text = 'Edited';
        color = 'orange';
        break;
      case 'A':
        text = 'Array Changed';
        color = 'blue';
        break;
      default:
        return '';
    }
    return <Chip size="small" style={{ background: color }} label={text} />;
  }

  renderCreateLog(data) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Operation</TableCell>
            <TableCell>State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.after.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Chip size="small" label={row.path.join(' > ')} />
                </TableCell>
                <TableCell>{this.renderKind(row.kind)}</TableCell>
                <TableCell>
                  <Chip size="small" label={JSON.stringify(row.rhs)} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  renderDeleteLog(data) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Operation</TableCell>
            <TableCell>Value before delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data.before).map(key => {
            return (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{this.renderKind('D')}</TableCell>
                <TableCell>
                  <Chip size="small" label={JSON.stringify(data.before[key])} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  renderEditValue(data) {
    return (
      <Fragment>
        {data.model === 'cerberusUseCase' && (
          <Fragment>
            <div>
              Cerberus use case input: <b>{data.before.input}</b>
            </div>
            <div>
              Cerberus use case: <b>{data.before.service}</b>
            </div>
          </Fragment>
        )}
        {data.model === 'user' && (
          <Fragment>
            Affected User is <b>{data.before.username}</b>
          </Fragment>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Operation</TableCell>
              <TableCell>Before</TableCell>
              <TableCell>After</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.after.map((row, index) => {
              return (
                <TableRow key={`row-${index}`}>
                  <TableCell component="th" scope="row">
                    <Chip size="small" label={row.path.join(' > ')} />
                  </TableCell>
                  <TableCell>{this.renderKind(row.kind)}</TableCell>
                  <TableCell>
                    <Chip size="small" label={JSON.stringify(row.lhs)} />
                  </TableCell>
                  <TableCell>
                    <Chip size="small" label={JSON.stringify(row.rhs)} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Fragment>
    );
  }

  renderChangesTable(data) {
    if (Object.keys(data).length > 0) {
      let table = '';
      if (data.operation === 'create') {
        table = this.renderCreateLog(data);
      } else if (data.operation === 'delete') {
        table = this.renderDeleteLog(data);
      } else if (data.operation === 'update') {
        table = this.renderEditValue(data);
      }
      return table;
    }
  }

  render() {
    const { classes, logs } = this.props;
    return (
      <div className={classes.container}>
        <Typography color="textSecondary">History</Typography>
        <Typography color="error">{this.state.errorWhileGetting}</Typography>
        {!this.state.errorWhileGetting && (
          <Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Id</TableCell>
                  <TableCell align="left">Model</TableCell>
                  <TableCell align="left">Operation</TableCell>
                  <TableCell align="left">User Name</TableCell>
                  <TableCell align="left">Creation Date</TableCell>
                  <TableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map(log => (
                  <TableRow key={log.id}>
                    <TableCell padding="dense" component="th" scope="row">
                      {log.id}
                    </TableCell>
                    <TableCell align="left">{log.model}</TableCell>
                    <TableCell align="left">
                      {this.renderKind(log.operation)}
                    </TableCell>
                    <TableCell align="left">{log.username}</TableCell>
                    <TableCell align="left">{log.createdAt}</TableCell>
                    <TableCell align="left">
                      <DetailsIcon onClick={() => this.onDetailClick(log)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Dialog
              open={this.state.detailsDialog}
              fullWidth={true}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <div className={classes.changesContainer}>
                  <h4>
                    Changed by &nbsp;
                    <Chip
                      avatar={
                        <Avatar>
                          <FaceIcon />
                        </Avatar>
                      }
                      label={this.state.current.username}
                      className={classes.chip}
                    />
                    &nbsp; on &nbsp;
                    <Chip
                      avatar={
                        <Avatar>
                          <CalendarToday />
                        </Avatar>
                      }
                      label={this.state.current.createdAt}
                      className={classes.chip}
                    />
                  </h4>
                  {this.renderChangesTable(this.state.current)}
                </div>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={() => this.onCloseDialog()}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(History);
