import React, { PureComponent, Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import removeFromArray from 'lodash/remove';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

const styles = {
  container: {
    padding: 16
  },
  buttonCreate: {
    float: 'right'
  }
};

class CerberusView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
      activeData: {},
      errorWhileGetting: '',
      dialogError: '',
      errorWhileDeleting: '',
      dataDialog: false,
      dialogDelete: false
    };

    this.getAllDatasets = this.getAllDatasets.bind(this);
    this.createData = this.createData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  async componentDidMount() {
    this.getAllDatasets();
  }

  async getAllDatasets() {
    try {
      const datasets = await this.props.client.all();
      if (Array.isArray(datasets)) {
        this.setState({ datasets });
      } else {
        if (datasets.message && !/^no.*found$/i.test(datasets.message))
          throw new Error(datasets.message || datasets);
      }
    } catch (error) {
      this.setState({ errorWhileGetting: error.message });
      console.error(error);
    }
  }

  static validateFields(data, validFields) {
    for (const field of validFields) {
      if (!data[field]) {
        throw new Error('All fields are mandatory !');
      }
    }
  }

  async createData() {
    const data = this.state.activeData;
    try {
      CerberusView.validateFields(data, this.props.validFields);
      const result = await this.props.client.create(data);
      if (isEmpty(result) || result.message) {
        //FIXME result.message contains error info. it is better to have result.error
        throw new Error(result.message || 'Oops! An error happened.');
      }
      this.getAllDatasets(); // properly get creation time and id of new data
      this.closeDialog();
    } catch (error) {
      this.setState({ dialogError: error.message || error });
      console.error(error);
    }
  }

  async deleteData(id) {
    try {
      await this.props.client.delete(id);
      removeFromArray(this.state.datasets, data => {
        return data.id === id;
      });
      this.closeDialog();
    } catch (error) {
      this.setState({ errorWhileDeleting: error.message || error });
      console.log(error);
    }
  }

  async updateData() {
    const data = this.state.activeData;
    try {
      CerberusView.validateFields(data, this.props.validFields);
      const result = await this.props.client.update(this.state.activeData);
      if (isEmpty(result) || result.message) {
        // FIXME  result.message contains error info. it is better to have result.error
        throw new Error(result.message || 'Oops! An error happened.');
      }
      const index = this.state.datasets.findIndex(
        data => data.id === this.state.activeData.id
      );
      this.setState({
        datasets: this.state.datasets.splice(index, 1, this.state.activeData)
      });
      this.closeDialog();
    } catch (error) {
      this.setState({ dialogError: error.message || error });
      console.error(error);
    }
  }

  handleChange(event) {
    this.setState({
      activeData: {
        ...this.state.activeData,
        [event.target.name]: event.target.value
      }
    });
  }

  onDeleteClick(data) {
    this.setState({
      dialogDelete: true,
      activeData: data
    });
  }

  onUpdateClick(data) {
    this.setState({
      dataDialog: 'Edit',
      activeData: data
    });
  }

  closeDialog() {
    this.setState({
      errorWhileGetting: '',
      dialogError: '',
      errorWhileDeleting: '',
      dataDialog: false,
      dialogDelete: false,
      activeData: {}
    });
  }

  renderDataDialog(dialog) {
    return (
      <Dialog
        open={!!dialog}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{dialog}</DialogTitle>
        <DialogContent>
          {this.props.content.map((field, index) => (
            <TextField
              {...field}
              autoFocus={index === 0}
              key={field.name}
              margin="dense"
              onChange={this.handleChange}
              defaultValue={this.state.activeData[field.name]}
              fullWidth
            />
          ))}
        </DialogContent>
        <Typography color="error" align="center">
          {this.state.dialogError}
        </Typography>
        <DialogActions>
          <Button color="secondary" onClick={() => this.closeDialog()}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() =>
              dialog === 'New' ? this.createData() : this.updateData()
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const excludedFields = ['updatedAt', 'deletedAt'];
    return (
      <div className={classes.container}>
        <Typography color="textSecondary">{this.props.title}</Typography>
        <Typography color="error">{this.state.errorWhileGetting}</Typography>
        {!this.state.errorWhileGetting && (
          <Fragment>
            <IconButton
              className={classes.buttonCreate}
              aria-label="Create"
              onClick={() => this.setState({ dataDialog: 'New' })}
            >
              <AddIcon />
            </IconButton>
            <Table>
              <TableHead>
                <TableRow>
                  {this.props.headers.map(field => (
                    <TableCell key={field} align="left">
                      {field}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.datasets.map(row => (
                  <TableRow key={row.id}>
                    {Object.entries(row)
                      .filter(([key]) => !excludedFields.includes(key))
                      .map(([, value]) =>
                        value === 'id' ? (
                          <TableCell padding="dense" component="th" scope="row">
                            {value}
                          </TableCell>
                        ) : (
                          <TableCell key={value} align="left">
                            {value}
                          </TableCell>
                        )
                      )}
                    <TableCell align="left">
                      <IconButton
                        className={classes.button}
                        aria-label="Edit"
                        onClick={() => this.onUpdateClick(row)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        className={classes.button}
                        aria-label="Delete"
                        onClick={() => this.onDeleteClick(row)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {this.renderDataDialog(this.state.dataDialog)}
        <Dialog
          open={this.state.dialogDelete}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.deletePrompt} <br />
              id: {this.state.activeData.id}
            </DialogContentText>
          </DialogContent>
          <Typography color="error" align="center">
            {this.state.errorWhileDeleting}
          </Typography>
          <DialogActions>
            <Button color="primary" onClick={() => this.closeDialog()}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={() => this.deleteData(this.state.activeData.id)}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(CerberusView);
