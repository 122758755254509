import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import HeaderBidding from '../components/header-bidding/header.bidding';
import BiddingHistory from '../components/shared/config-history';
import apiClient from '../api-client';

const styles = {
  paper: {
    height: '100%',
    background: 'rgba(255,255,255,0.2)'
  },
  grid: {
    'margin-bottom': 16
  }
};

const initialData = new Array(24)
  .fill(5000)
  .map((frequency, hour) => ({ hour, activated: false, frequency }));

class HeaderBiddingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historyKey: new Date().getTime(),
      totalErrors: 0,
      initial: true,
      biddingData: {
        key: '',
        active: true,
        config: initialData
      }
    };
    this.handleKey = this.handleKey.bind(this);
    this.reActivate = this.reActivate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  static async fetcher(type, id) {
    const result = await apiClient.headerBiddingConfig[type](id);
    if (result.message || result.error) {
      throw new Error(result.message || result.error);
    }
    return result;
  }

  setCleanState({ id, ...data }) {
    this.setState({
      totalErrors: 0,
      initial: true,
      loadedId: id,
      ...data
    });
  }

  async componentDidMount() {
    try {
      const [biddingData, history] = await Promise.all([
        HeaderBiddingPage.fetcher('active'),
        HeaderBiddingPage.fetcher('history')
      ]);
      const { id } = biddingData;
      this.setCleanState({ id, biddingData, history });
    } catch (error) {
      this.setState({ errorWhileGetting: 'No saved configuration yet!' });
      console.error(error);
    }
  }

  async reActivate(id) {
    try {
      await HeaderBiddingPage.fetcher('activate', id);
      const [biddingData, history] = await Promise.all([
        HeaderBiddingPage.fetcher('get', id),
        HeaderBiddingPage.fetcher('history')
      ]);
      this.setCleanState({ id, biddingData, history });
    } catch (error) {
      this.setState({
        errorWhileRestoring: 'Oops! An error occured while restoring history'
      });
      console.error(error);
    }
  }

  async getById(id) {
    try {
      const biddingData = await HeaderBiddingPage.fetcher('get', id);
      this.setCleanState({ id, biddingData });
    } catch (error) {
      this.setState({
        errorWhileGetting: 'Oops! An error occured while getting config'
      });
      console.error(error);
    }
  }

  onBiddingDataRestore() {
    this.setState({ configurationKey: new Date().getTime() });
  }

  handleSaveClick() {
    this.setState({ showSaveDialog: true });
  }

  handleCloseDialog() {
    this.setState({
      showSaveDialog: false,
      key: '',
      saveError: ''
    });
  }

  handleKey(key) {
    this.setState({ key });
  }

  handleChange(data) {
    this.setState({
      initial: false,
      biddingData: data
    });
  }

  async handleSave() {
    try {
      if (!this.state.key)
        throw new Error('You cannot upload a configuration without a key');

      const result = await apiClient.headerBiddingConfig.set({
        key: this.state.key,
        active: this.state.biddingData.active,
        config: this.state.biddingData.config
      });

      if (result.message || result.errors || result.error) {
        throw new Error(result.message || result.errors || result.error);
      }

      const history = await HeaderBiddingPage.fetcher('history');

      this.setState({
        history,
        showSaveDialog: false,
        saveSuccess: true,
        dialogSave: false,
        initial: true,
        key: ''
      });
    } catch (error) {
      this.setState({
        saveError: error.message || error
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid
          container
          spacing={8}
          alignItems="stretch"
          className={classes.grid}
        >
          <Grid item lg={8} md={12}>
            <Paper className={classes.paper}>
              <HeaderBidding
                key={this.state.configurationKey}
                errors={this.state.totalErrors}
                tab={this.state.tab}
                biddingData={this.state.biddingData}
                initial={this.state.initial}
                saveSuccess={this.state.saveSuccess}
                errorOnSave={this.state.saveError}
                showSaveDialog={this.state.showSaveDialog}
                onSave={this.handleSave.bind(this)}
                onChange={this.handleChange}
                onSaveButtonClick={this.handleSaveClick}
                onCloseDialog={this.handleCloseDialog}
                onKeyChange={this.handleKey}
              />
            </Paper>
          </Grid>
          <Grid item lg={4} md={12} xs={12}>
            <Paper className={classes.paper}>
              <BiddingHistory
                key={this.state.historyKey}
                history={this.state.history}
                loadedId={this.state.loadedId}
                activate={this.reActivate}
                onGetSelectedConfig={id => this.getById(id)}
                errorWhileGetting={this.state.errorWhileGetting}
                errorWhileRestoring={this.state.errorWhileRestoring}
                onRestore={() => this.onBiddingDataRestore()}
              />
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(HeaderBiddingPage);
