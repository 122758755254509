import { withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const styles = {
  dialogCloseButton: {
    position: 'absolute',
    right: 20,
    top: 16
  }
};

const initialState = {
  caseNo: '',
  context: '',
  description: '',
  state: 'referenced',
  payload: {},
  enable: false
};

const ToggleFormModal = ({ classes, ...props }) => {
  const [model, setModel] = useState(initialState);
  const [isInvalidPayload, setIsInvalidPayload] = useState(false);
  let invalidPayload = false;

  const updateModel = obj => {
    const newModel = { ...model, ...obj };
    setModel(newModel);
  };

  const updatePayloadValidationState = value => {
    setIsInvalidPayload(value);
    invalidPayload = value;
  };

  const validatePayload = () => {
    try {
      if (model.payload && JSON.stringify(model.payload) !== '{}') {
        JSON.parse(model.payload);
      }
      updatePayloadValidationState(false);
    } catch (e) {
      updatePayloadValidationState(true);
    }
  };

  const handleButtonClick = (button, event) => {
    event.preventDefault();

    if (button && button.type === 'primary') {
      if (!event.currentTarget.form.reportValidity()) {
        return false;
      }
      validatePayload();

      if (invalidPayload) {
        return false;
      }
    }

    const modifiedModel = {
      ...model,
      payload:
        typeof model.payload === 'string'
          ? JSON.parse(model.payload)
          : model.payload
    };

    button.action(modifiedModel);
  };

  useEffect(() => {
    setIsInvalidPayload(false);
    setModel(props.selectedToggle ? props.selectedToggle : initialState);
  }, [props.open, props.selectedToggle]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle id="form-dialog-title">
        {props.title}
        <IconButton
          aria-label="close"
          className={classes.dialogCloseButton}
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <form>
        <DialogContent>
          <TextField
            autoFocus
            required
            fullWidth
            margin="normal"
            id="caseNo"
            name="caseNo"
            label="Case Number"
            value={model.caseNo}
            onChange={e => updateModel({ caseNo: e.target.value })}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="context"
            name="context"
            label="Context"
            value={model.context}
            onChange={e => updateModel({ context: e.target.value })}
          />
          <TextField
            fullWidth
            margin="normal"
            id="description"
            name="description"
            label="Description"
            value={model.description}
            onChange={e => updateModel({ description: e.target.value })}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="state">State</InputLabel>
            <Select
              value={model.state}
              onChange={e => updateModel({ state: e.target.value })}
              autoWidth={true}
              inputProps={{
                name: 'state',
                id: 'state'
              }}
            >
              <MenuItem value={'referenced'}>referenced</MenuItem>
              <MenuItem value={'obsolete'}>obsolete</MenuItem>
            </Select>
          </FormControl>

          <TextField
            error={isInvalidPayload}
            fullWidth
            margin="normal"
            id="payload"
            name="payload"
            label="Payload"
            multiline
            rows="3"
            value={
              typeof model.payload === 'string'
                ? model.payload
                : JSON.stringify(model.payload)
            }
            helperText={
              isInvalidPayload ? 'provide valid json in string format' : ''
            }
            onChange={e => updateModel({ payload: e.target.value })}
          />
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  disabled={model.state === 'obsolete'}
                  checked={model.enable}
                  onChange={e => updateModel({ enable: e.target.checked })}
                  color="primary"
                />
              }
              label="Enable"
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          {props.buttons &&
            props.buttons.map(button => (
              <Button
                key={button.title}
                color={button.type ? button.type : 'secondary'}
                type="button"
                onClick={e => handleButtonClick(button, e)}
              >
                {button.title}
              </Button>
            ))}
        </DialogActions>
      </form>
    </Dialog>
  );
};

ToggleFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.func,
      type: PropTypes.string
    })
  )
};

export default withStyles(styles)(ToggleFormModal);
