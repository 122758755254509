import { withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import apiClient from '../api-client';
import ToggleToolbar from '../components/feature-toggle/toggle-toolbar';
import TogglePanel from '../components/feature-toggle/toggle-panel';

const styles = {
  title: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBottom: '15px'
  },
  paper: {
    background: 'rgba(255,255,255,0.2)',
    height: '100%'
  },
  grid: {
    marginBottom: 16
  }
};

const getAllToggles = async () => {
  try {
    return await apiClient.featureToggle.all();
  } catch (error) {
    console.log(error);
  }
};

const FeatureTogglePage = ({ classes }) => {
  const [toggles, setToggles] = useState([]);
  const [visibleToggles, setVisibleToggles] = useState([]);
  const [triggerReload, setTriggerReload] = useState(false);
  const searchText = React.useRef('');

  function filterVisibleTogglesFromList(list) {
    const result = list.filter(toggle =>
      Object.values(toggle).find(el => String(el).includes(searchText.current))
    );
    setVisibleToggles(result);
  }

  function onSearch(text) {
    searchText.current = text;
    filterVisibleTogglesFromList(toggles);
  }

  const onToggleUpdate = async toggle => {
    try {
      await apiClient.featureToggle.update(toggle);
      setTriggerReload(!triggerReload);
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const onToggleCreate = async toggle => {
    try {
      await apiClient.featureToggle.create(toggle);
      setTriggerReload(!triggerReload);
    } catch (error) {
      console.log(error);
    }
  };

  const onToggleDelete = async toggle => {
    try {
      await apiClient.featureToggle.delete(toggle.id);
      setTriggerReload(!triggerReload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      const list = await getAllToggles();
      filterVisibleTogglesFromList(list);
      setToggles(list);
    })();
  }, [triggerReload]);

  return (
    <div style={{ width: '100%' }}>
      <Typography className={classes.title}>Feature Toggle</Typography>
      <Grid
        container
        spacing={16}
        alignItems="stretch"
        className={classes.grid}
      >
        <Grid item lg={12}>
          <Paper className={classes.paper}>
            <ToggleToolbar onCreate={onToggleCreate} onSearch={onSearch} />
            <TogglePanel
              toggles={visibleToggles}
              onDelete={onToggleDelete}
              onUpdate={onToggleUpdate}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(FeatureTogglePage);
