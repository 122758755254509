import React from 'react';

const PageHome = () => (
  <svg viewBox="0 0 1400 400" width="88%" height="400">
    <title>Background</title>
    <rect
      x="-1"
      y="-1"
      width="100%"
      height="400"
      fillOpacity="0.2"
      fill="#fff"
    />
    <title>Main</title>
    <g
      fill="#e50040"
      transform="translate(0,295) scale(0.10000000149011612,-0.10000000149011612) "
    >
      <path d="m612.326334,1429.268756l0,-109.570044l232.358062,0l232.01022,0l-114.787665,-114.091982c-62.959295,-62.959295 -184.008106,-182.268899 -268.533568,-265.402995l-153.745903,-151.311013l77.220793,-79.655683l77.220793,-79.307841l309.578854,312.013744l309.578854,312.361586l1.739207,-309.231013l1.739207,-308.883172l107.135154,-1.043524l106.787313,-0.695683l0,452.193832l0,452.193832l-459.150661,0l-459.150661,0l0,-109.570044z" />
      <path d="m1061.041753,916.89836c-66.785551,-22.26185 -109.917885,-91.830132 -98.439119,-158.963524c20.174802,-118.613921 161.746255,-164.876828 246.271718,-80.351366c71.307489,70.959648 49.39348,189.573568 -42.784493,232.705903c-19.82696,9.391718 -28.175154,11.130925 -54.611101,11.826608c-23.305374,0.695683 -36.175507,-0.695683 -50.437004,-5.217621z" />
    </g>
    <text
      fill="#671E43"
      x="193.584966"
      y="183.432423"
      fontSize="18.5"
      fontFamily="'Roboto', 'Oxygen', 'Helvetica Neue'"
      textAnchor="start"
      transform="matrix(4.709899881898894,0,0,5.09,-747.389988227411,-706.1626057686545) "
      fontWeight="bold"
    >
      SHIVA CONFIGURATION TOOL
    </text>
  </svg>
);

export default PageHome;
