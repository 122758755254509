import 'core-js/es7';
import 'regenerator-runtime/runtime';

import AsyncIteratorFrom from 'stream-to-async-iterator';
import { TextDecoder } from 'text-encoding-polyfill';

const buildQueryString = queryParams => {
  const paramsList = Object.entries(queryParams).map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  );
  return `?${paramsList.join('&')}`;
};

export const createScope = (baseOrPrevScope, segments = [], queryParams) => {
  let extendedSegments = segments;
  let base = baseOrPrevScope;

  if (typeof baseOrPrevScope === 'object') {
    const { segments: prevSegments, base: prevBase } = baseOrPrevScope;
    extendedSegments = [...prevSegments, ...extendedSegments];
    base = prevBase;
  }

  return {
    base,
    segments: extendedSegments,
    queryParams
  };
};

const compileScope = ({ base, segments, queryParams }) => {
  const separator = '/';
  const prefix = base === '' || base === separator ? '' : separator;
  const fixedBase = base.charAt(0) === separator ? base.slice(1) : base;
  const queryString = queryParams ? buildQueryString(queryParams) : '';
  return prefix + [fixedBase, ...segments].join(separator) + queryString;
};

export const createAction = (name, actionResolver) => scope => [
  name,
  (...args) => actionResolver(scope, name, ...args)
];

// prettier-ignore
const defaultActionResolverCreators = {
  get: createAction('get',
    (scope, name, id, options) => (
      ['GET', createScope(scope, [id]), undefined, options]
    )
  ),

  delete: createAction('delete',
    (scope, name, id, options) => (
      ['DELETE', createScope(scope, [id]), undefined, options]
    )
  ),

  create: createAction('create',
    (scope, name, data, options) => (
      ['POST', scope, data, options]
    )
  ),

  update: createAction('update',
    (scope, name, data, options) => (
      ['PUT', createScope(scope, [data.id]), data, options]
    )
  ),

  all: createAction('all',
    (scope, name, options) => (
      ['GET', scope, undefined, options]
    )
  )
};

export const getResolverCreatorByActionName = name => {
  const actionResolverCreator = defaultActionResolverCreators[name];

  if (!actionResolverCreator) {
    throw new Error(`No resolver was found for action: ${name}`);
  }

  return actionResolverCreator;
};

function* chunkGenerator(value, prev = '') {
  const chunks = value.split('\n');
  let prevAcc = prev;
  for (const chunk of chunks) {
    try {
      yield JSON.parse(prevAcc + chunk);
      prevAcc = '';
    } catch {
      prevAcc += chunk;
    }
  }
  return prevAcc;
}

async function* handleBrowserReadableStream(stream) {
  const utf8 = new TextDecoder('utf-8');
  const reader = stream.getReader();
  let prevAcc = '';

  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      return;
    }

    const decodedValue = utf8.decode(value);
    prevAcc = yield* chunkGenerator(decodedValue, prevAcc);
  }
}

async function* handleNodeReadableStream(stream) {
  const iterableStream = new AsyncIteratorFrom(stream);
  let prevAcc = '';
  for await (const value of iterableStream) {
    const decodedValue = value.toString();
    prevAcc = yield* chunkGenerator(decodedValue, prevAcc);
  }
}

function readResponseBodyInChunks({ body }) {
  if (body.constructor.name === 'ReadableStream') {
    return handleBrowserReadableStream(body);
  }

  return handleNodeReadableStream(body);
}

export const createRequestProcessor = ({
  authorization,
  baseUrl,
  fetch,
  defaultHeaders,
  defaultRequestOptions,
  statusHandlers
}) => {
  const findHandlerByStatus = status => ([statusOrRange, handler]) => {
    if (statusOrRange.includes(',')) {
      const [from, to] = statusOrRange.split(',').map(Number);
      return status >= from && status <= to && handler;
    }
    return status === Number(statusOrRange) && handler;
  };

  const handleResponseByStatus = (response, { asChunks }) => {
    const [, handler] =
      Object.entries(statusHandlers).find(
        findHandlerByStatus(response.status)
      ) || [];

    if (handler) {
      return handler.call(null, response);
    }

    if (response.ok) {
      return asChunks ? readResponseBodyInChunks(response) : response.json();
    }

    const error = new Error(`${response.status} ${response.statusText}`);
    error.response = response;
    throw error;
  };

  const requestProcessor = async (
    method,
    scope,
    data,
    options = { headers: {}, asChunks: false }
  ) => {
    const path = compileScope(scope);
    const url = `${baseUrl}${path}`;
    const authKey =
      typeof authorization === 'function' ? authorization() : authorization;
    const requestOptions = {
      url,
      method,
      body: JSON.stringify(data),
      ...defaultRequestOptions,
      ...options,
      headers: {
        Authorization: await authKey,
        ...defaultHeaders,
        ...options.headers
      }
    };

    const response = await fetch(url, requestOptions);

    return handleResponseByStatus(response, options);
  };

  return requestProcessor;
};

export const createApiCreator = requestProcessor => (scope, actions) => {
  return actions.reduce((api, actionNameOrResolverCreator) => {
    let actionName;
    let actionResolver;

    if (typeof actionNameOrResolverCreator === 'function') {
      [actionName, actionResolver] = actionNameOrResolverCreator(scope);
    }

    if (typeof actionNameOrResolverCreator === 'string') {
      [actionName, actionResolver] = getResolverCreatorByActionName(
        actionNameOrResolverCreator
      )(scope);
    }

    const apiHandler = (...args) =>
      requestProcessor(...actionResolver(...args));

    Object.defineProperty(api, actionName, {
      enumerable: true,
      writable: false,
      configurable: true,
      value: apiHandler
    });

    return api;
  }, {});
};
