import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CerberusView from '../components/shared/cerberus-shared-view';
import apiClient from '../api-client';

const styles = {
  paper: {
    height: '100%',
    background: 'rgba(255,255,255,0.2)'
  },
  grid: {
    'margin-bottom': 16
  }
};
const columnNames = [
  'Id',
  'Input',
  'Service',
  'Ref (Transport)',
  'Request Rate',
  'Request Drop Rate',
  'Creation Date',
  ''
];
const dialogContent = [
  { name: 'input', label: 'Input' },
  { name: 'service', label: 'Service' },
  {
    name: 'ref',
    label: "Ref (Transport) - You can use '*' to match all transports"
  },
  { name: 'requestRate', label: 'Request Rate', type: 'number' },
  { name: 'requestDropRate', label: 'Request Drop Rate', type: 'number' }
];
const validFields = [
  'input',
  'service',
  'ref',
  'requestRate',
  'requestDropRate'
];

const PageCerberusUseCase = ({ classes }) => (
  <div>
    <Grid container spacing={16} alignItems="stretch" className={classes.grid}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <CerberusView
            title="Cerberus Use Cases"
            deletePrompt="Do you really want to delete this use case?"
            headers={columnNames}
            content={dialogContent}
            client={apiClient.cerberusUseCase}
            validFields={validFields}
          />
        </Paper>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(PageCerberusUseCase);
