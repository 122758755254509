import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';

const validateNewFrequency = val => {
  if (!val) return false;
  if (val < 5 || val > 1e5) return false;
  return true;
};

const handleFrequencyChange = (props, { target: { value } }) => {
  const { hour, activated, frequency } = props;
  const numValue = Number(value);
  const isValid = validateNewFrequency(numValue);
  const change = {
    hour,
    activated,
    frequency: isValid ? numValue : frequency
  };
  props.onChange(change);
};

const handleActiveChange = (props, { target: { value } }) => {
  const { hour, frequency } = props;
  const activated = value === 'false' ? true : false;
  const change = { hour, frequency, activated };
  props.onChange(change);
};

const BiddingRow = props => {
  const commonProps = {
    margin: 'dense',
    variant: 'outlined',
    fullWidth: true
  };
  return (
    <Fragment>
      <Grid item xs={9}>
        <Grid
          component="label"
          container
          alignItems="center"
          justify="flex-start"
        >
          <Grid item style={{ marginRight: '12px', marginLeft: '20px' }}>
            <Typography>Every</Typography>
          </Grid>

          <Grid item>
            <TextField
              {...commonProps}
              type="number"
              id="frequency"
              name="frequency"
              label=""
              value={props.frequency}
              onChange={event => handleFrequencyChange(props, event)}
            ></TextField>
          </Grid>

          <Grid item style={{ marginLeft: '12px' }}>
            <Typography> ad request leads to header bidding</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3} style={{ paddingTop: '12px' }}>
        <Grid component="label" container alignItems="center" justify="center">
          <Grid item>Off</Grid>
          <Grid item>
            <Switch
              name="activated"
              onChange={event => handleActiveChange(props, event)}
              checked={props.activated}
              value={props.activated}
              color="primary"
            />
          </Grid>
          <Grid item>On</Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

BiddingRow.defaultProps = {
  hour: -1,
  frequency: 5,
  activated: false
};

export default BiddingRow;
