import React, { Fragment, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GlobalIcon from '@material-ui/icons/Public';
import DayIcon from '@material-ui/icons/Today';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const dayTab = {
  width: '80px',
  minWidth: '80px'
};

const checkNotEmpty = (index, recurring) => {
  return recurring.find(({ localDay }) => index === localDay);
};

const populateRecurring = (days, filledDays) => {
  const type = 'recurring';
  return days.map((name, i) => {
    const index = i + 1; // index is 1-based
    return { name, index, type, hasData: checkNotEmpty(index, filledDays) };
  });
};

const tabs = props => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const scheduledData = !!props.scheduled.length;
  return [
    { type: 'global', name: 'Global', index: 0 },
    ...populateRecurring(days, props.filledDays),
    { type: 'timebox', name: 'Scheduled', hasData: scheduledData, index: 8 }
  ];
};

const handleTabClick = (props, setNotActive, event, index) => {
  if (props.isActive) {
    props.onChange(tabs(props)[index]);
  } else {
    setNotActive(true);
  }
};

const handleClose = setNotActive => {
  setNotActive(false);
};

const getTab = tab => {
  switch (tab.type) {
    case 'global':
      return (
        <Tab
          key={tab.type}
          label={tab.name}
          style={{ minWidth: '80px' }}
          icon={<GlobalIcon />}
        />
      );
    case 'timebox':
      return (
        <Tab
          key={tab.type}
          label={`${tab.name}${tab.hasData ? '*' : ''}`}
          style={{ minWidth: '80px' }}
          icon={<ScheduleIcon />}
        />
      );
    default:
      return (
        <Tab
          key={`${tab.type}-${tab.index}`}
          label={`${tab.name}${tab.hasData ? '*' : ''}`}
          style={dayTab}
          icon={<DayIcon />}
        />
      );
  }
};

const IntensityCalendarTabs = props => {
  const [notActive, setNotActive] = useState(false);
  return (
    <Fragment>
      <Tabs
        value={props.tabIndex}
        onChange={(...data) => handleTabClick(props, setNotActive, ...data)}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="secondary"
      >
        {tabs(props).map(tab => getTab(tab))}
      </Tabs>
      <Dialog
        open={notActive}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Some values are incorrect !
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            One or more filed(s) which you have changed are containing invalid
            values.
            <br />
            Please fix it before navigating to other tab.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(setNotActive)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

IntensityCalendarTabs.defaultProps = {
  filledDays: [],
  scheduled: []
};

export default IntensityCalendarTabs;
