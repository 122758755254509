import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      root: {
        backgroundColor: '#611F44'
      }
    },
    MuiPaper: {
      root: {
        // backgroundColor: 'rgba(255,255,255,0.2)',
      }
    },
    MuiTableCell: {
      root: {
        borderBottomColor: '#611F44'
      }
    }
  },
  spacing: {
    unit: 8
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#E50140'
    },
    secondary: {
      main: '#FFFFFF'
    }
  },
  background: {
    paper: '#ff0000',
    default: '#ff0000'
  },
  typography: { useNextVariants: true }
});

export default theme;
