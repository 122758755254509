import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import IntensityRow from './intensity.row';
import CalendarTabs from './calendar.tabs';
import RecurringView from './recurringview';
import TimeboxView from './timeboxview';
import {
  getUTCHour,
  onGlobalChange,
  getDayData,
  onRecurringChange,
  onRecurringAdd,
  onRecurringRemove,
  onTimeboxChange,
  onTimeboxAdd,
  onTimeboxRemove,
  handleConfigActiveChange
} from './utils';
import Snackbar from '@material-ui/core/Snackbar';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';

const styles = {
  container: {
    padding: 16
  },
  buttonCreate: {
    float: 'right'
  },
  inputMargin: {
    margin: 4
  }
};

const TabContainer = props => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
};

const AdIntensity = props => {
  const { tab, errors, classes, initial, leaveTab, adIntensityData } = props;
  adIntensityData.global.forEach(row => (row.hour = getUTCHour(row)));
  return (
    <div className={classes.container}>
      <Typography color="textSecondary">Switch-In Ad Intensity</Typography>
      <Chip size="small" style={{ background: 'red' }} label="Spot" />
      <Typography color="error">{props.errorWhileGetting}</Typography>

      {!props.errorWhileGetting && (
        <Fragment>
          <Fab
            disabled={!!errors || initial}
            variant="extended"
            color="primary"
            size="small"
            style={{ float: 'right', marginBottom: '10px' }}
            onClick={props.onSaveButtonClick}
          >
            <NavigationIcon className={classes.extendedIcon} />
            Save All
          </Fab>
          <AppBar position="static" color="default">
            <CalendarTabs
              tabIndex={tab.index}
              onChange={props.onCalendarTabChange}
              isActive={leaveTab}
              filledDays={adIntensityData.recurring}
              scheduled={adIntensityData.timebox}
            />
          </AppBar>
          {tab.type === 'global' && (
            <TabContainer>
              <Grid container spacing={8}>
                {adIntensityData.global.map(row => (
                  <IntensityRow
                    key={row.localHour}
                    localHour={row.localHour}
                    adDay={row.adDay}
                    percentage={row.percentage}
                    fixedTime={true}
                    exclusion={true}
                    excluded={row.excluded}
                    onChange={data =>
                      onGlobalChange(props, row.localHour, data)
                    }
                    hasError={row.hasError}
                  />
                ))}
              </Grid>
            </TabContainer>
          )}
          {tab.type === 'recurring' && (
            <TabContainer>
              <RecurringView
                key={`${tab.type}-${tab.index}`}
                data={getDayData(tab.index, adIntensityData)}
                onChange={(...data) => onRecurringChange(props, ...data)}
                onAdd={data => onRecurringAdd(props, data)}
                onRemove={data => onRecurringRemove(props, data)}
              />
            </TabContainer>
          )}
          {tab.type === 'timebox' && (
            <TabContainer>
              <TimeboxView
                key={tab.type}
                data={adIntensityData.timebox}
                onChange={(...data) => onTimeboxChange(props, ...data)}
                onAdd={data => onTimeboxAdd(props, data)}
                onRemove={data => onTimeboxRemove(props, data)}
              />
            </TabContainer>
          )}
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            open={props.saveSuccess}
            autoHideDuration={3000}
            onClose={props.onCloseSnackBar}
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
            message={<span id="message-id">Intensity configuration saved</span>}
          />

          <Dialog
            open={props.showSaveDialog}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Save</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="key"
                name="key"
                label="Configuration history key"
                onChange={event => props.onKeyChange(event.target.value)}
                fullWidth={true}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adIntensityData.active}
                    onChange={event => handleConfigActiveChange(props, event)}
                    value="checked"
                  />
                }
                label="Activate this configuration now"
              />
              <Typography color="error" align="center">
                {props.errorOnSave}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={() => props.onCloseDialog()}>
                Cancel
              </Button>
              <Button
                disabled={!props.isSaveEnabled}
                color="primary"
                onClick={() => props.onSave()}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </div>
  );
};

AdIntensity.defaultProps = {
  tab: { type: 'global', name: 'Global', index: 0 },
  errorWhileGetting: '',
  leaveTab: true,
  isSaveAllEnabled: true,
  isSaveEnabled: true,
  errorOnSave: '',
  showSaveDialog: false,
  saveSuccess: false
};

export default withStyles(styles)(AdIntensity);
