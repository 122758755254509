import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AdIntensitySpot from '../components/switchin-ad-intensity/switchin.ad.intensity.spot';
import AdIntensityHistory from '../components/shared/config-history';
import apiClient from '../api-client';
import { format, addLocalTime } from '../utils/adintensity-spot-utils';

const styles = {
  paper: {
    height: '100%',
    background: 'rgba(255,255,255,0.2)'
  },
  grid: {
    'margin-bottom': 16
  }
};

const fetcher = async (type, id) => {
  const result = await apiClient.adIntensitySpotConfig[type](id);
  if (result.message || result.error) {
    throw new Error(result.message || result.error);
  }
  return result;
};

class AdIntensityPage extends Component {
  constructor(props) {
    super(props);
    const global = new Array(24).fill([1]).map((adDay, localHour) => ({
      adDay,
      localHour,
      percentage: 50,
      excluded: localHour === 19 || localHour === 20
    }));

    this.state = {
      historyKey: new Date().getTime(),
      totalErrors: 0,
      initial: true,
      adIntensityData: {
        key: '',
        active: true,
        global,
        recurring: [],
        timebox: []
      }
    };

    this.totalErrors = 0;
    this.leaveTab = true;
    this.handleKey = this.handleKey.bind(this);
    this.reActivate = this.reActivate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.handleCalendarTabChange = this.handleCalendarTabChange.bind(this);
  }

  setCleanState({ id, ...data }) {
    this.setState({
      totalErrors: 0,
      initial: true,
      loadedId: id,
      ...data
    });
  }

  async componentDidMount() {
    try {
      const [data, history] = await Promise.all([
        fetcher('active'),
        fetcher('history')
      ]);
      const { id } = data;
      this.setCleanState({ id, adIntensityData: addLocalTime(data), history });
    } catch (error) {
      this.setState({ errorWhileGetting: 'No saved configuration yet!' });
      console.error(error);
    }
  }

  async reActivate(id) {
    try {
      await fetcher('activate', id);
      const [data, history] = await Promise.all([
        fetcher('get', id),
        fetcher('history')
      ]);
      this.setCleanState({ id, adIntensityData: addLocalTime(data), history });
    } catch (error) {
      this.setState({
        errorWhileRestoring: 'Oops! An error occured while restoring history'
      });
      console.error(error);
    }
  }

  async getById(id) {
    try {
      const data = await fetcher('get', id);
      this.setCleanState({ id, adIntensityData: addLocalTime(data) });
    } catch (error) {
      this.setState({
        errorWhileGetting: 'Oops! An error occured while getting config'
      });
      console.error(error);
    }
  }

  onAdIntensityRestore() {
    this.setState({ configurationKey: new Date().getTime() });
  }

  handleCalendarTabChange(tab) {
    this.setState({ tab });
  }

  validateAdDays(config) {
    const adDay = Array.isArray(config.adDay)
      ? config.adDay
      : config.adDay.split(',');
    const isInvalid = adDay.some(value => !Number(value));
    if (isInvalid) {
      if (!config.hasError) {
        this.totalErrors += 1;
        config.hasError = true;
        this.leaveTab = false;
      }
    } else {
      if (config.hasError) {
        config.hasError = false;
        this.totalErrors -= 1;
        this.leaveTab = true;
      }
    }
  }

  parseStatusToBoolean(config) {
    if (config.excluded !== undefined) {
      config.excluded =
        config.excluded || config.excluded === 'true' ? true : false;
    }
  }

  handleSaveClick() {
    this.setState({ showSaveDialog: true });
  }

  closeSnackBar() {
    this.setState({ saveSuccess: false });
  }

  handleCloseDialog() {
    this.setState({
      showSaveDialog: false,
      key: '',
      saveError: ''
    });
  }

  handleKey(key) {
    this.setState({ key });
  }

  handleChange(adIntensityData) {
    adIntensityData.global.forEach(config => {
      this.validateAdDays(config);
    });
    adIntensityData.recurring.forEach(config => {
      this.validateAdDays(config);
    });
    adIntensityData.timebox.forEach(config => {
      this.validateAdDays(config);
    });
    this.setState({
      adIntensityData,
      totalErrors: this.totalErrors,
      initial: false,
      leaveTab: this.leaveTab
    });
  }

  async handleSave() {
    try {
      if (!this.state.key)
        throw new Error('You cannot upload a configuration without a key');
      const result = await apiClient.adIntensitySpotConfig.set({
        key: this.state.key,
        active: this.state.adIntensityData.active,
        ...format(this.state.adIntensityData)
      });

      if (result.message || result.errors || result.error) {
        throw new Error(result.message || result.errors || result.error);
      }

      const history = await fetcher('history');

      this.setState({
        history,
        showSaveDialog: false,
        saveSuccess: true,
        dialogSave: false,
        initial: true,
        key: ''
      });
    } catch (error) {
      this.setState({
        saveError: error.message || error
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid
          container
          spacing={8}
          alignItems="stretch"
          className={classes.grid}
        >
          <Grid item lg={8} md={12}>
            <Paper className={classes.paper}>
              <AdIntensitySpot
                key={this.state.configurationKey}
                errors={this.state.totalErrors}
                tab={this.state.tab}
                leaveTab={this.state.leaveTab}
                adIntensityData={this.state.adIntensityData}
                initial={this.state.initial}
                saveSuccess={this.state.saveSuccess}
                errorOnSave={this.state.saveError}
                showSaveDialog={this.state.showSaveDialog}
                onSave={this.handleSave.bind(this)}
                onCalendarTabChange={this.handleCalendarTabChange}
                onChange={this.handleChange}
                onSaveButtonClick={this.handleSaveClick}
                onCloseDialog={this.handleCloseDialog}
                onKeyChange={this.handleKey}
                onCloseSnackBar={this.closeSnackBar}
              />
            </Paper>
          </Grid>
          <Grid item lg={4} md={12}>
            <Paper className={classes.paper}>
              <AdIntensityHistory
                key={this.state.historyKey}
                history={this.state.history}
                loadedId={this.state.loadedId}
                activate={this.reActivate}
                onGetSelectedConfig={id => this.getById(id)}
                errorWhileGetting={this.state.errorWhileGetting}
                errorWhileRestoring={this.state.errorWhileRestoring}
                onRestore={() => this.onAdIntensityRestore()}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AdIntensityPage);
