import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import apiClient from '../../api-client';

const ADMIN_READ = 'admin:read';
const ADMIN_WRITE = 'admin:write';

const styles = {
  container: {
    padding: 16
  },
  buttonCreate: {
    float: 'right'
  },
  ellipsis: {
    maxWidth: '130px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

class RoleManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRole: { permissions: [] },
      errorWhileGetting: '',
      errorWhileCreating: '',
      errorWhileDeleting: '',
      errorWhileUpdating: '',
      dialogCreate: false,
      dialogUpdate: false,
      dialogDelete: false
    };

    this.createRole = this.createRole.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.updateRole = this.updateRole.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handlePermissionChange = this.handlePermissionChange.bind(this);
  }

  static formatDefaultPermissions(permissions) {
    const permissionsSet = new Set();
    permissions.forEach(permission => {
      const [name] = permission.name.split(':');
      permissionsSet.add(name);
    });
    return Array.from(permissionsSet);
  }

  static prepareRole(role) {
    if (!role.name || !role.description)
      throw new Error('Name and description are mandatory !');

    const uniqPerms = Array.from(new Set(role.permissions));
    if (uniqPerms.includes(ADMIN_READ) && uniqPerms.includes(ADMIN_WRITE))
      return {
        ...role,
        permissions: [ADMIN_READ, ADMIN_WRITE]
      };

    if (uniqPerms.includes(ADMIN_READ))
      return {
        ...role,
        permissions: uniqPerms.filter(
          perm => !perm.includes('read') || perm.includes('admin')
        )
      };

    if (uniqPerms.includes(ADMIN_WRITE))
      return {
        ...role,
        permissions: uniqPerms.filter(
          perm => !perm.includes('write') || perm.includes('admin')
        )
      };

    return { ...role, permissions: uniqPerms };
  }

  handlePermissionChange({ target: { checked, name } }) {
    const { currentRole } = this.state;
    const currentPermissions = currentRole.permissions || [];
    if (checked)
      if (name.startsWith('admin')) {
        const [, accessType] = name.split(':');
        const adminPermissions = this.props.allPermissions
          .filter(permission => permission.name.includes(accessType))
          .map(({ name }) => name);
        const permissions = [...currentPermissions, ...adminPermissions];
        this.setState({
          currentRole: {
            ...currentRole,
            permissions: [...currentPermissions, ...permissions]
          }
        });
      } else
        this.setState({
          currentRole: {
            ...currentRole,
            permissions: [...currentPermissions, name]
          }
        });
    else {
      const [, accessType] = name.split(':');
      this.setState({
        currentRole: {
          ...currentRole,
          permissions: [
            ...currentPermissions.filter(
              perm => perm !== name && perm !== `admin:${accessType}`
            )
          ]
        }
      });
    }
  }

  async createRole() {
    try {
      const role = { ...this.state.currentRole };
      const preparedRole = RoleManagement.prepareRole(role);
      const result = await apiClient.role.create(preparedRole);
      if (result.error || result.message) {
        throw new Error(result.error || result.message);
      }
      role.id = result.id;
      this.props.onCreate(role);
      this.closeDialog();
    } catch (error) {
      this.setState({ errorWhileCreating: error.message });
      console.error(error);
    }
  }

  async deleteRole(id) {
    try {
      const result = await apiClient.role.delete(id);
      if (result.status > 399) throw result.message || result.error;
      this.props.onDelete(id);
      this.closeDialog();
    } catch (error) {
      this.setState({ errorWhileDeleting: error.message || error });
      console.error(error);
    }
  }

  async updateRole() {
    try {
      const role = { ...this.state.currentRole };
      const preparedRole = RoleManagement.prepareRole(role);
      const result = await apiClient.role.update(preparedRole);
      if (result.error || result.message) {
        throw new Error(result.error || result.message);
      }
      this.props.onUpdate(role);
      this.closeDialog();
    } catch (error) {
      this.setState({ errorWhileUpdating: error.message || error });
      console.error(error);
    }
  }

  handleChange(event) {
    this.setState({
      currentRole: {
        ...this.state.currentRole,
        [event.target.name]: event.target.value
      }
    });
  }

  onDeleteClick(role) {
    this.setState({
      dialogDelete: true,
      currentRole: role
    });
  }

  onUpdateClick(role) {
    this.setState({
      dialogUpdate: true,
      currentRole: role
    });
  }

  closeDialog() {
    this.setState({
      errorWhileGetting: '',
      errorWhileCreating: '',
      errorWhileDeleting: '',
      errorWhileUpdating: '',
      dialogCreate: false,
      dialogUpdate: false,
      dialogDelete: false,
      currentRole: { permissions: [] }
    });
  }

  createDialog(allPermissions, classes, textProps, tableCellProps) {
    const { currentRole } = this.state;
    const permissions = currentRole.permissions || [];
    return (
      <DialogContent>
        <Grid
          container
          spacing={8}
          alignItems="stretch"
          className={classes.grid}
        >
          <Grid item lg={5} md={12}>
            <TextField
              autoFocus
              defaultValue={currentRole.name}
              {...textProps('name', 'name', 'Name')}
            />
            <TextField
              defaultValue={currentRole.description}
              {...textProps('description', 'description', 'Description')}
            />
            <TextField
              defaultValue={currentRole.emailGroup}
              {...textProps('emailGroup', 'emailGroup', 'E-mail (group)')}
            />
          </Grid>
          <Grid item lg={7} md={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" />
                  <TableCell align="left">Read access</TableCell>
                  <TableCell align="left">Write access</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {allPermissions.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell {...tableCellProps}>{row}</TableCell>
                    <TableCell align="left" padding="none">
                      <Checkbox
                        name={`${row}:read`}
                        checked={permissions.includes(`${row}:read`)}
                        onChange={this.handlePermissionChange}
                      />
                    </TableCell>
                    <TableCell align="left" padding="none">
                      <Checkbox
                        name={`${row}:write`}
                        checked={permissions.includes(`${row}:write`)}
                        onChange={this.handlePermissionChange}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
    );
  }

  render() {
    const { classes } = this.props;
    const textFieldProps = (id, name, label) => ({
      id,
      name,
      label,
      margin: 'dense',
      onChange: this.handleChange,
      fullWidth: true
    });

    const iconButtonProps = label => ({
      className: classes.buttonCreate,
      'aria-label': label
    });

    const tableCellProps = {
      align: 'left',
      padding: 'dense',
      className: classes.ellipsis
    };
    const formattedPermissions = RoleManagement.formatDefaultPermissions(
      this.props.allPermissions
    );

    return (
      <div className={classes.container}>
        <Typography color="textSecondary">Role Management</Typography>
        <Typography color="error">{this.state.errorWhileGetting}</Typography>
        {!this.state.errorWhileGetting && (
          <Fragment>
            <IconButton
              {...iconButtonProps('Create')}
              onClick={() => this.setState({ dialogCreate: true })}
            >
              <AddIcon />
            </IconButton>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">E-mail (group)</TableCell>
                  <TableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.roles.map(row => (
                  <TableRow key={row.id}>
                    <TableCell {...tableCellProps}>{row.name}</TableCell>
                    <TableCell {...tableCellProps}>{row.description}</TableCell>
                    <TableCell {...tableCellProps}>{row.emailGroup}</TableCell>
                    <TableCell align="left" padding="none">
                      <IconButton
                        {...iconButtonProps('Edit')}
                        onClick={() => this.onUpdateClick(row)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        {...iconButtonProps('Delete')}
                        onClick={() => this.onDeleteClick(row)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}

        <Dialog
          open={this.state.dialogCreate}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">New Role</DialogTitle>
          {this.createDialog(
            formattedPermissions,
            classes,
            textFieldProps,
            tableCellProps
          )}
          <Typography color="error" align="center">
            {this.state.errorWhileCreating}
          </Typography>
          <DialogActions>
            <Button color="secondary" onClick={() => this.closeDialog()}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => this.createRole()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogDelete}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to delete role "{this.state.currentRole.name}"
              ?
            </DialogContentText>
          </DialogContent>
          <Typography color="error" align="center">
            {this.state.errorWhileDeleting}
          </Typography>
          <DialogActions>
            <Button color="primary" onClick={() => this.closeDialog()}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={() => this.deleteRole(this.state.currentRole.id)}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogUpdate}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit</DialogTitle>
          {this.createDialog(
            formattedPermissions,
            classes,
            textFieldProps,
            tableCellProps
          )}
          <Typography color="error" align="center">
            {this.state.errorWhileUpdating}
          </Typography>
          <DialogActions>
            <Button color="secondary" onClick={() => this.closeDialog()}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => this.updateRole()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(RoleManagement);
