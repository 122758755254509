import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import RestoreIcon from '@material-ui/icons/Restore';
import PersonIcon from '@material-ui/icons/Person';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import GavelIcon from '@material-ui/icons/Gavel';
import TimelineIcon from '@material-ui/icons/Timeline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitIcon from '@material-ui/icons/ExitToApp';
import ChangePassIcon from '@material-ui/icons/Build';
import LivestreamIcon from '@material-ui/icons/LiveTv';
import MappingFilesIcon from '@material-ui/icons/Replay';
import SitescriptsIcon from '@material-ui/icons/Subject';
import FeatureToggleIcon from '@material-ui/icons/Traffic';
import PageAdIntensity from './ad.intensity';
import PageAdIntensitySpot from './ad.intensity.spot';
import PageFeatureToggle from './feature-toggle';
import PageHeaderBidding from './header.bidding';
import PageSitescripts from './sitescripts';
import PageCerberusUseCase from './cerberus.usecase';
import PageCerberusDashboard from './cerberus.dashboard';
import PageHistoryLogs from './history.logs';
import PageUserControl from './user.control';
import PageLivestreamTrigger from './livestream-trigger';
import PageReloadMappingFiles from './reload-mapping-files';
import PageHome from './page.home';
import ChangePasswordDialog from '../components/change-password';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1
    },
    '& .userInfo': {
      display: 'none'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {}
});

const mainOptions = [
  { key: 'adIntensity', text: 'Ad Intensity', path: '/ad-intensity' },
  {
    key: 'adIntensitySpot',
    text: 'Ad Intensity Spot',
    path: '/ad-intensity-si-spot'
  },
  { key: 'cerberus_useCase', text: 'Cerberus UseCase', path: '/cerberus' },
  { key: 'sitescripts', text: 'Sitescripts', path: '/sitescripts/overview' },
  {
    key: 'somquery_headerBidding',
    text: 'Header Bidding',
    path: '/header-bidding'
  },
  {
    key: 'cerberus_dashboard',
    text: 'Cerberus Dashboard',
    path: '/cerberus-dashboard'
  },
  {
    key: 'livestream',
    text: 'Livestream Trigger',
    path: '/livestream-trigger'
  },
  {
    key: 'mapping_files',
    text: 'Reload Mapping Files',
    path: '/reload-mapping-files'
  },
  {
    key: 'feature_toggle',
    text: 'Feature Toggle',
    path: '/feature-toggle'
  }
].sort((a, b) => {
  if (a.text < b.text) return -1;
  if (a.text > b.text) return 1;
  return 0;
});

const adminOptions = [
  { key: 'historyLogs', text: 'History Logs', path: '/logs' },
  { key: 'userControl', text: 'User Control', path: '/users' }
];

const generalOptions = [{ key: 'logout', text: 'Logout', path: '/login' }];
const dialogOptions = [{ key: 'changePass', text: 'Change Password' }];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      showDialog: false
    };
  }

  handleDrawerToggle() {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  toggleChangePassDialog() {
    this.setState(prevState => ({
      showDialog: !prevState.showDialog
    }));
  }

  static getPermissions() {
    const encoded = atob(window.localStorage.getItem('perms'));
    const views = encoded.split(',').map(p => p.replace(/:read|:write/, ''));

    return Array.from(new Set(views));
  }

  static getPage(path = '') {
    switch (path) {
      case '/ad-intensity':
        return 'adIntensity';
      case '/ad-intensity-si-spot':
        return 'adIntensitySpot';
      case '/header-bidding':
        return 'headerBidding';
      case '/sitescripts/*':
        return 'sitescripts';
      case '/cerberus':
        return 'cerberus';
      case '/cerberus-dashboard':
        return 'cerberusDashboard';
      case '/users':
        return 'userControl';
      case '/logs':
        return 'historyLogs';
      case '/livestream-trigger':
        return 'livestreamTrigger';
      case '/reload-mapping-files':
        return 'reloadMappingFiles';
      case '/feature-toggle':
        return 'featureToggle';
      default:
        return '';
    }
  }

  render() {
    const { classes, match } = this.props;
    const views = Dashboard.getPermissions();
    const user = {
      name: window.localStorage.getItem('username'),
      role: window.localStorage.getItem('role')
    };
    const listItemProps = key => ({
      key,
      name: key,
      button: true,
      style: { paddingLeft: '25px' }
    });
    const page = Dashboard.getPage(match.path);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open
            })
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <span title={`${user.name} - ${user.role}`} className="userInfo">
              {user.name} - {user.role}
            </span>
            <IconButton onClick={() => this.handleDrawerToggle()}>
              {this.state.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {mainOptions.map(
              item =>
                (views.includes('admin') || views.includes(item.key)) && (
                  <Link key={item.key} to={item.path}>
                    <ListItem {...listItemProps(item.key)}>
                      {item.key === 'adIntensity' && <LineStyleIcon />}
                      {item.key === 'adIntensitySpot' && <BlurLinearIcon />}
                      {item.key === 'somquery_headerBidding' && <GavelIcon />}
                      {item.key === 'cerberus_useCase' && <TimelineIcon />}
                      {item.key === 'sitescripts' && <SitescriptsIcon />}
                      {item.key === 'cerberus_dashboard' && <DashboardIcon />}
                      {item.key === 'feature_toggle' && <FeatureToggleIcon />}
                      {item.key === 'livestream' && <LivestreamIcon />}
                      {item.key === 'mapping_files' && <MappingFilesIcon />}
                      {this.state.open && <ListItemText primary={item.text} />}
                    </ListItem>
                  </Link>
                )
            )}
          </List>
          {views.includes('admin') && (
            <Fragment>
              <Divider />
              <List>
                {adminOptions.map(item => (
                  <Link key={item.key} to={item.path}>
                    <ListItem {...listItemProps(item.key)}>
                      {item.key === 'userControl' && <PersonIcon />}
                      {item.key === 'historyLogs' && <RestoreIcon />}
                      {this.state.open && <ListItemText primary={item.text} />}
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Fragment>
          )}
          <Divider />
          <List>
            {dialogOptions.map(item => (
              <ListItem
                {...listItemProps(item.key)}
                onClick={() => this.toggleChangePassDialog()}
              >
                {item.key === 'changePass' && <ChangePassIcon />}
                {this.state.open && <ListItemText primary={item.text} />}
              </ListItem>
            ))}
            {generalOptions.map(item => (
              <Link key={item.key} to={item.path}>
                <ListItem {...listItemProps(item.key)}>
                  {item.key === 'logout' && <ExitIcon />}
                  {this.state.open && <ListItemText primary={item.text} />}
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
        {page === 'cerberus' && <PageCerberusUseCase />}
        {page === 'cerberusDashboard' && <PageCerberusDashboard />}
        {page === 'sitescripts' && <PageSitescripts />}
        {page === 'headerBidding' && <PageHeaderBidding />}
        {page === 'adIntensity' && <PageAdIntensity />}
        {page === 'adIntensitySpot' && <PageAdIntensitySpot />}
        {page === 'featureToggle' && <PageFeatureToggle />}
        {page === 'userControl' && <PageUserControl />}
        {page === 'historyLogs' && <PageHistoryLogs />}
        {page === 'livestreamTrigger' && <PageLivestreamTrigger />}
        {page === 'reloadMappingFiles' && <PageReloadMappingFiles />}
        {page === '' && <PageHome />}
        <ChangePasswordDialog
          show={this.state.showDialog}
          onClose={() => this.toggleChangePassDialog()}
        />
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Dashboard);
