const prepareAdDay = adDay =>
  typeof adDay === 'string'
    ? adDay.split(',').map(val => Number.parseInt(val))
    : adDay;

const prepareGlobal = data => {
  if (!data || !Array.isArray(data))
    throw new Error('Global property must be an array');
  if (data.length !== 24)
    throw new Error('Global property must have 24 objects');

  return data.map(({ adDay, percentage, hour, excluded }) => ({
    adDay: prepareAdDay(adDay),
    percentage: Number.parseInt(percentage),
    hour: Number.parseInt(hour),
    excluded: excluded || excluded === 'true' ? true : false
  }));
};

const prepareRecurring = data => {
  if (!data || !Array.isArray(data) || !data.length) return [];

  return data.map(({ adDay, percentage, hour, day }) => ({
    adDay: prepareAdDay(adDay),
    percentage: Number.parseInt(percentage),
    hour: Number.parseInt(hour),
    day: Number.parseInt(day)
  }));
};

const prepareTimebox = data => {
  if (!data || !Array.isArray(data) || !data.length) return [];

  return data.map(({ adDay, percentage, start, end }) => ({
    adDay: prepareAdDay(adDay),
    percentage: Number.parseInt(percentage),
    start: new Date(start).getTime(),
    end: new Date(end).getTime()
  }));
};

const localiseGlobal = (offset, { global }) =>
  global
    .map(config => {
      let localHour = config.hour - offset;
      if (localHour === 24) localHour = 0;
      if (localHour === 25) localHour = 1;
      return { ...config, localHour };
    })
    .sort((a, b) => {
      if (a.localHour > b.localHour) return 1;
      if (a.localHour < b.localHour) return -1;
      return 0;
    });

const localiseRecurring = (offset, { recurring }) =>
  recurring.map(config => {
    let localHour = config.hour - offset;
    let localDay = config.day;
    if (localHour === 24) {
      localHour = 0;
      localDay += 1;
    }
    if (localHour === 25) {
      localHour = 1;
      localDay += 1;
    }
    if (localDay === 8) localDay = 1;
    return { ...config, localHour, localDay };
  });

const localiseTimebox = (offset, { timebox }) =>
  timebox.map(config => {
    const timeOffset = offset * 60 * 60 * 1000;
    const localTimeStart = config.start - timeOffset;
    const localTimeEnd = config.end - timeOffset;
    return { ...config, localTimeStart, localTimeEnd };
  });

export const format = data => ({
  global: prepareGlobal(data.global),
  recurring: prepareRecurring(data.recurring),
  timebox: prepareTimebox(data.timebox)
});

export const addLocalTime = config => {
  const offset = new Date().getTimezoneOffset() / 60;
  return {
    ...config,
    global: localiseGlobal(offset, config),
    recurring: localiseRecurring(offset, config),
    timebox: localiseTimebox(offset, config)
  };
};
