import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import ToggleFormModal from './toggle-form-modal';

const styles = {
  search: {
    margin: '20px 30px',
    width: '40%',
    'min-width': '200px'
  },
  container: {
    display: 'flex',
    'justify-content': 'flex-end',
    'margin-bottom': '40px'
  }
};

const ToggleToolbar = ({ classes, ...props }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [searchInputTimeout, setSearchInputTimeout] = useState(null);

  const handleSearchChange = event => {
    var searchText = event.target.value;
    if (searchInputTimeout) clearTimeout(searchInputTimeout);
    setSearchInputTimeout(
      setTimeout(() => {
        props.onSearch(searchText);
      }, 300)
    );
  };

  const createToggle = async toggle => {
    await props.onCreate(toggle);
    handleClose();
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const buttonConfig = [
    {
      title: 'Create',
      action: createToggle,
      type: 'primary'
    },
    {
      title: 'Cancel',
      action: handleClose
    }
  ];

  return (
    <div className={classes.container}>
      <TextField
        className={classes.search}
        autoFocus
        margin="dense"
        variant="outlined"
        id="search"
        name="search"
        label="Search..."
        style={{}}
        value={props.searchText}
        onChange={event => handleSearchChange(event)}
      />

      <Tooltip title="Create Toggle">
        <Fab
          variant="extended"
          color="primary"
          size="small"
          style={{ float: 'right', margin: '25px 20px 20px 10px' }}
          onClick={() => setOpenModal(true)}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <ToggleFormModal
        open={openModal}
        title="Create New Toggle"
        onClose={handleClose}
        buttons={buttonConfig}
      />
    </div>
  );
};

export default withStyles(styles)(ToggleToolbar);
