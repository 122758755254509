import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Switch, Route, Link, Redirect, withRouter } from 'react-router-dom';

import Single from '../components/sitescripts/single-sitescript';
import Overview from '../components/sitescripts/overview-sitescript';

const styles = {
  title: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBottom: '15px'
  }
};

const invertView = view => {
  if (view === 'single') return 'overview';
  if (view === 'overview') return 'single';
};

const toggleView = (view, setView) => {
  setView(invertView(view));
};

const PageSitescripts = ({ classes, ...props }) => {
  const [view, setView] = useState('overview');

  return (
    <div style={{ width: '100%' }}>
      <Typography className={classes.title}>
        Sitescripts configuration Viewer
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => toggleView(view, setView)}
      >
        <Link to={`/sitescripts/${invertView(view)}`}>Switch View</Link>
      </Button>
      <Switch>
        <Route path="/sitescripts/overview" component={Overview} />
        <Route path="/sitescripts/single/:id?" component={Single} />
        <Redirect to="/sitescripts/overview" />
      </Switch>
    </div>
  );
};

export default withStyles(styles)(withRouter(PageSitescripts));
