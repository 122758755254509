import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CerberusView from '../components/shared/cerberus-shared-view';
import apiClient from '../api-client';

const styles = {
  paper: {
    height: '100%',
    background: 'rgba(255,255,255,0.2)'
  },
  grid: {
    'margin-bottom': 16
  }
};
const columnNames = [
  'Id',
  'ViewName',
  'Display Time',
  'Location',
  'Creation Date',
  ''
];
const dialogContent = [
  { name: 'viewName', label: 'View Name' },
  { name: 'displayTime', label: 'Display Time', type: 'number' },
  { name: 'location', label: 'Location' }
];
const validFields = ['viewName', 'displayTime', 'location'];

const PageCerberusDashboard = ({ classes }) => (
  <div>
    <Grid container spacing={16} alignItems="stretch" className={classes.grid}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <CerberusView
            title="Cerberus Dashboard"
            deletePrompt="Do you really want to delete this view?"
            headers={columnNames}
            content={dialogContent}
            client={apiClient.cerberusDashboardView}
            validFields={validFields}
          />
        </Paper>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(PageCerberusDashboard);
