import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import History from '../components/history/history';
import apiClient from '../api-client';

const styles = {
  paper: {
    height: '100%',
    background: 'rgba(255,255,255,0.2)'
  },
  grid: {
    'margin-bottom': 16
  }
};

const getAllHistory = async () => {
  try {
    const logs = await apiClient.history.all();
    return logs;
  } catch (error) {
    console.log(error);
  }
};

const HistoryLogs = ({ classes }) => {
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    (async () => setLogs(await getAllHistory()))();
  }, []);
  return (
    <div>
      <Grid
        container
        spacing={16}
        alignItems="stretch"
        className={classes.grid}
      >
        <Grid item lg={12} md={12}>
          <Paper className={classes.paper}>
            <History logs={logs} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(HistoryLogs);
