import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BiddingRow from './bidding.row';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  container: {
    padding: 16
  },
  buttonCreate: {
    float: 'right'
  },
  inputMargin: {
    margin: 4
  }
};

const handleConfigActiveChange = (props, { target }) => {
  const active = target.checked;
  props.onChange({ ...props.biddingData, active });
};

const handleBiddingDataChange = (props, data) => {
  props.onChange({
    ...props.biddingData,
    config: props.biddingData.config.map(item => ({
      ...item,
      frequency: data.frequency,
      activated: data.activated
    }))
  });
};

const Bidding = props => {
  const { errors, initial, classes, biddingData } = props;
  return (
    <div className={classes.container}>
      <Typography color="textSecondary">Header Bidding</Typography>
      <Typography color="error">{props.errorWhileGetting}</Typography>
      <Fab
        disabled={!!errors || initial}
        variant="extended"
        color="primary"
        size="small"
        className="saveButton"
        style={{ float: 'right', marginBottom: '10px' }}
        onClick={() => props.onSaveButtonClick()}
      >
        <SaveIcon className={classes.extendedIcon} />
        Save
      </Fab>
      <Grid container spacing={8}>
        {/* Using the only the first element of the config array as we will use it to set all others */}
        <BiddingRow
          key={biddingData.config[0].hour}
          hour={biddingData.config[0].hour}
          frequency={biddingData.config[0].frequency}
          activated={biddingData.config[0].activated}
          hasError={biddingData.config[0].hasError}
          onChange={data => handleBiddingDataChange(props, data)}
        />
      </Grid>
      <Dialog open={props.showSaveDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Save</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="key"
            name="key"
            label="Configuration history key"
            onChange={event => props.onKeyChange(event.target.value)}
            fullWidth={true}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={biddingData.active}
                onChange={event => handleConfigActiveChange(props, event)}
                value="checked"
              />
            }
            label="Activate this configuration now"
          />
          <Typography color="error" align="center">
            {props.errorOnSave}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => props.onCloseDialog()}>
            Cancel
          </Button>
          <Button
            disabled={!props.isSaveEnabled}
            color="primary"
            onClick={() => props.onSave()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Bidding.defaultProps = {
  biddingData: [],
  errorWhileGetting: '',
  isSaveAllEnabled: true,
  isSaveEnabled: true,
  errorOnSave: '',
  showSaveDialog: false,
  saveSuccess: false
};

export default withStyles(styles)(Bidding);
