import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IntensityRow from './intensity.row';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';

const styles = {
  container: {
    padding: 16
  }
};

class TimeboxView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMissingField: false,
      newRow: {}
    };

    this.onAddClick = this.onAddClick.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  }

  handleNewChange(data) {
    this.setState({ newRow: data });
  }

  static validateMandatoryFields({
    localTimeStart,
    localTimeEnd,
    adDay,
    percentage
  }) {
    return (
      localTimeStart && localTimeEnd && adDay && adDay.toString() && percentage
    );
  }

  onAddClick() {
    if (TimeboxView.validateMandatoryFields(this.state.newRow)) {
      this.props.onAdd(this.state.newRow);
      this.setState({ newRow: {} });
      return;
    }
    this.setState({ errorMissingField: true });
  }

  handleSnackbarClose() {
    this.setState({
      errorMissingField: false
    });
  }

  render() {
    const { data } = this.props;
    const { newRow } = this.state;
    return (
      <Grid container spacing={8}>
        {data.map((row, index) => (
          <IntensityRow
            key={index}
            adDay={row.adDay}
            percentage={row.percentage}
            removeButton={true}
            flextime={true}
            localTimeStart={row.localTimeStart}
            localTimeEnd={row.localTimeEnd}
            hasError={row.hasError}
            onChange={data => this.props.onChange(index, data)}
            onRemoveClick={this.props.onRemove}
          />
        ))}
        <Grid item xs={12}>
          <Divider />
          <Chip
            style={{ marginTop: '20px', float: 'left' }}
            color="secondary"
            label="New Row"
            variant="outlined"
          />
        </Grid>
        <IntensityRow
          key={newRow.hour}
          localTimeStart={newRow.localTimeStart}
          localTimeEnd={newRow.localTimeEnd}
          adDay={newRow.adDay}
          percentage={newRow.percentage}
          addButton={true}
          flextime={true}
          onChange={newData => this.handleNewChange(newData)}
          onAddClick={this.onAddClick}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={this.state.errorMissingField}
          onClose={this.handleSnackbarClose}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={
            <span id="message-id">All fields are mandatory for new row</span>
          }
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(TimeboxView);
