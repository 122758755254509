import createShivaApiClient from '@seven-one-media/shiva-api-client';

const buildBaseUrl = () => {
  const baseDomain = 'shiva.sqrt-5041.de';
  const environment = TARGET_ENV || ''; // eslint-disable-line no-undef
  const { protocol } = global.location;

  switch (environment) {
    case 'production':
      return `${protocol}//${baseDomain}`;
    case 'development':
    case 'staging':
      return `${protocol}//${environment}.${baseDomain}`;
    default:
      return `${protocol}//localhost:9999`;
  }
};

export default createShivaApiClient({
  authorization: () => global.localStorage.getItem('shiva'),
  baseUrl: buildBaseUrl(),
  fetch: global.fetch,
  defaultHeaders: {
    'Content-Type': 'application/json'
  },
  statusHandlers: {
    // handle response within the given status range
    '400,499': response => {
      const getResponse = () => response.clone();
      return getResponse()
        .json()
        .then(body => ({ ...body, status: response.status }))
        .catch(() => getResponse().text());
    }
  }
});
