import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import UserManagement from '../components/user-management/user.management';
import RoleManagement from '../components/user-management/role.management';

import apiClient from '../api-client';

const styles = {
  paper: {
    height: '100%',
    width: '100%',
    background: 'rgba(255,255,255,0.2)'
  },
  grid: {
    'margin-bottom': 16
  }
};

class UserControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      roles: [],
      permissions: []
    };
  }

  componentDidMount() {
    this.getAllRoles();
    this.getAllUsers();
    this.getAllPermissions();
  }

  async getAllPermissions() {
    try {
      const permissions = await apiClient.permissions.all();
      if (isEmpty(permissions) || permissions.message) {
        throw new Error(
          'Oops! An error happened while getting permissions list.'
        );
      }
      this.setState({ permissions });
    } catch (error) {
      this.setState({ errorWhileGetting: error.message });
      console.error(error);
    }
  }

  async getAllUsers() {
    try {
      const users = await apiClient.user.all();
      if (isEmpty(users) || users.message) {
        throw new Error('Oops! An error happened while getting user list.');
      }
      this.setState({ users });
    } catch (error) {
      this.setState({ errorWhileGetting: error.message });
      console.error(error);
    }
  }

  async getAllRoles() {
    try {
      const roles = await apiClient.role.all();
      if (isEmpty(roles) || roles.message) {
        throw new Error('Oops! An error happened while getting role list.');
      }
      this.setState({ roles });
    } catch (error) {
      console.error(error);
    }
  }

  onRoleCreate(role) {
    this.setState({
      roles: [...this.state.roles, role]
    });
  }

  onRoleUpdate(updatedRole) {
    this.setState(state => ({
      roles: state.roles.map(role =>
        role.id === updatedRole.id ? updatedRole : role
      )
    }));
  }

  onRoleDelete(id) {
    this.setState({
      roles: this.state.roles.filter(role => role.id !== id)
    });
  }

  onUserCreate(user) {
    this.setState({
      users: [...this.state.users, user]
    });
  }

  onUserUpdate(updatedUser) {
    this.setState(state => ({
      users: state.users.map(user =>
        user.id === updatedUser.id ? updatedUser : user
      )
    }));
  }

  onUserDelete(id) {
    this.setState({
      users: this.state.users.filter(user => user.id !== id)
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid
          container
          spacing={8}
          alignItems="stretch"
          className={classes.grid}
        >
          <Grid item lg={6} md={12}>
            <Paper className={classes.paper}>
              <RoleManagement
                roles={this.state.roles}
                allPermissions={this.state.permissions}
                onCreate={data => this.onRoleCreate(data)}
                onUpdate={data => this.onRoleUpdate(data)}
                onDelete={data => this.onRoleDelete(data)}
              />
            </Paper>
          </Grid>
          <Grid item lg={6} md={12}>
            <Paper className={classes.paper}>
              <UserManagement
                roles={this.state.roles}
                users={this.state.users}
                onCreate={data => this.onUserCreate(data)}
                onUpdate={data => this.onUserUpdate(data)}
                onDelete={data => this.onUserDelete(data)}
              />
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(UserControl);
