import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const getDateFromMillisecond = ms => {
  const date = new Date(ms);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const dayOfMonth = ('0' + date.getDate()).slice(-2);
  const hour = ('0' + date.getHours()).slice(-2);
  const minute = ('0' + date.getMinutes()).slice(-2);
  return `${date.getFullYear()}-${month}-${dayOfMonth}T${hour}:${minute}`;
};

const gethourOptions = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push({
      hour: i
    });
  }
  return hours;
};

const parseBoolean = value =>
  value === true || value === 'true' ? true : false;

const handleChange = (props, { target: { name, value } }) => {
  if (name === 'excluded') {
    // because of a bug in material ui, component is always returns 'true/false' as string. we should parse it to boolean
    value = parseBoolean(value);
  }
  const changes = { ...props, [name]: value };
  props.onChange(changes);
};

const onAddClick = props => {
  props.onAddClick();
};

const onRemoveClick = props => {
  const key = props.flextime ? props.localTimeStart : props.localHour;
  props.onRemoveClick(key);
};

const toggleByExcluded = value => {
  return value === 'true' || value === true;
};

const IntensityRow = props => {
  const commonProps = {
    margin: 'dense',
    variant: 'outlined',
    fullWidth: true,
    onChange: event => handleChange(props, event)
  };
  return (
    <Fragment>
      {!props.flextime && (
        <Grid item xs={3}>
          <TextField
            {...commonProps}
            select
            id={'hour-' + props.localHour}
            key={props.localHour || props.localTimeStart}
            name="localHour"
            label="Time Slot"
            disabled={props.fixedTime}
            SelectProps={{
              native: true
            }}
            defaultValue={props.localHour}
          >
            <option key={-1} value={-1}>
              ---
            </option>
            {gethourOptions().map(e => (
              <option key={e.hour} value={e.hour}>
                {e.hour}:00 - {e.hour}:59
              </option>
            ))}
          </TextField>
        </Grid>
      )}
      {props.flextime && (
        <Fragment>
          <Grid item xs={3}>
            <TextField
              {...commonProps}
              key={props.localTimeStart}
              id="localTimeStart"
              name="localTimeStart"
              label="Start"
              type="datetime-local"
              defaultValue={getDateFromMillisecond(props.localTimeStart)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              {...commonProps}
              key={props.localTimeEnd}
              id="localTimeEnd"
              name="localTimeEnd"
              label="End"
              type="datetime-local"
              defaultValue={getDateFromMillisecond(props.localTimeEnd)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Fragment>
      )}
      <Grid item xs={props.flextime ? 3 : 4}>
        <TextField
          {...commonProps}
          error={props.hasError}
          key={props.localHour || props.localTimeStart}
          id={'adDay-' + props.localHour}
          name="adDay"
          label="Keys"
          disabled={toggleByExcluded(props.excluded)}
          value={props.adDay}
        />
      </Grid>
      <Grid item xs={props.flextime ? 2 : props.exclusion ? 3 : 4}>
        <TextField
          {...commonProps}
          select
          key={props.localHour || props.localTimeStart}
          id={'percent-' + props.localHour}
          name="percentage"
          label="Percent after defined keys"
          SelectProps={{
            native: true
          }}
          disabled={toggleByExcluded(props.excluded)}
          value={props.percentage}
        >
          <option key={70} value={70}>
            70%
          </option>
          <option key={50} value={50}>
            50%
          </option>
          <option key={33} value={33}>
            33%
          </option>
          <option key={25} value={25}>
            25%
          </option>
          <option key={20} value={20}>
            20%
          </option>
        </TextField>
      </Grid>
      <Grid item xs={props.exclusion ? 2 : 1}>
        {props.removeButton && (
          <IconButton
            aria-label="Remove"
            style={{ marginTop: '10px' }}
            onClick={event => onRemoveClick(props, event)}
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
        )}
        {props.addButton && (
          <IconButton
            aria-label="Add"
            style={{ marginTop: '10px' }}
            onClick={event => onAddClick(props, event)}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        )}
        {props.exclusion && (
          <TextField
            {...commonProps}
            select
            key={props.excluded}
            id={'status-' + props.excluded}
            name="excluded"
            label="Status"
            inputProps={props.excluded && { style: { color: 'tomato' } }}
            SelectProps={{
              native: true
            }}
            value={props.excluded || false}
          >
            <option key={true} value={true}>
              excluded
            </option>
            <option key={false} value={false}>
              included
            </option>
          </TextField>
        )}
      </Grid>
    </Fragment>
  );
};

IntensityRow.defaultProps = {
  localHour: -1,
  adDay: [],
  percentage: 50,
  addButton: false,
  removeButton: false,
  exclusion: false,
  excluded: false
};

export default IntensityRow;
