import React from 'react';
import { Route, HashRouter as Router, Redirect } from 'react-router-dom';
import Login from './pages/login';
import Dashboard from './pages/dashboard';

const authenticationKey = () => {
  return window.localStorage.getItem('shiva');
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authenticationKey() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const Main = () => (
  <main>
    <Router>
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/cerberus" component={Dashboard} />
      <PrivateRoute exact path="/cerberus-dashboard" component={Dashboard} />
      <PrivateRoute exact path="/ad-intensity" component={Dashboard} />
      <PrivateRoute exact path="/ad-intensity-si-spot" component={Dashboard} />
      <PrivateRoute exact path="/sitescripts/*" component={Dashboard} />
      <PrivateRoute exact path="/header-bidding" component={Dashboard} />
      <PrivateRoute exact path="/users" component={Dashboard} />
      <PrivateRoute exact path="/logs" component={Dashboard} />
      <PrivateRoute exact path="/feature-toggle" component={Dashboard} />
      <PrivateRoute exact path="/livestream-trigger" component={Dashboard} />
      <PrivateRoute exact path="/reload-mapping-files" component={Dashboard} />
      <Route exact path="/login" component={Login} />
    </Router>
  </main>
);

export default Main;
