import React, { PureComponent, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import RestoreIcon from '@material-ui/icons/Restore';
import DetailsIcon from '@material-ui/icons/Details';
import FlagIcon from '@material-ui/icons/Flag';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  container: {
    padding: 16
  },
  buttonCreate: {
    float: 'right'
  },
  ellipsis100: {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  ellipsis70: {
    maxWidth: '70px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

class ConfigHistory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      restoreConfirmationDialog: false,
      moreDetailsDialog: false,
      moreDetailsItem: ''
    };

    this.restore = this.restore.bind(this);
  }

  confirmRestore(id) {
    this.idToRestore = id;
    this.setState({
      restoreConfirmationDialog: true
    });
  }

  restore() {
    this.closeDialog();
    this.props.activate(this.idToRestore);
  }

  moreDetails(row) {
    this.setState({
      moreDetailsDialog: true,
      moreDetailsItem: row
    });
  }

  closeDialog() {
    this.setState({
      restoreConfirmationDialog: false,
      errorWhileRestoring: '',
      moreDetailsDialog: false,
      moreDetailsItem: ''
    });
  }

  static getDateFromMillisecond(ms) {
    const date = new Date(ms);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const dayOfMonth = `0${date.getDate()}`.slice(-2);
    const hour = `0${date.getHours()}`.slice(-2);
    const minute = `0${date.getMinutes()}`.slice(-2);
    return `${date.getFullYear()}-${month}-${dayOfMonth} ${hour}:${minute}`;
  }

  renderTableRow(row, props) {
    return (
      <TableRow
        onClick={() => props.onGetSelectedConfig(row.id)}
        selected={props.loadedId === row.id}
      >
        <TableCell
          align="left"
          style={{ paddingRight: 5 }}
          className={props.classes.ellipsis100}
        >
          <Tooltip title={row.username} placement="left">
            <span>{row.username}</span>
          </Tooltip>
        </TableCell>
        <TableCell align="left" className={props.classes.ellipsis100}>
          {row.key}
        </TableCell>
        <TableCell align="left">
          {ConfigHistory.getDateFromMillisecond(row.updatedAt)}
        </TableCell>
        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
          <DetailsIcon onClick={() => this.moreDetails(row)} />
        </TableCell>
        {!row.active && (
          <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
            <RestoreIcon onClick={() => this.confirmRestore(row.id)} />
          </TableCell>
        )}
        {row.active && (
          <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>
            <FlagIcon />
          </TableCell>
        )}
      </TableRow>
    );
  }

  static renderMoreDetails(details) {
    return (
      <DialogContentText>
        ID: <strong>{details.id}</strong>
        <br />
        User ID: <strong>{details.user_id}</strong>
        <br />
        Key: <strong>{details.key}</strong>
        <br />
        User Name: <strong>{details.username}</strong>
        <br />
        Status:{' '}
        <span style={{ color: details.active ? 'green' : 'red' }}>
          <strong>{details.active ? 'active' : 'inactive'}</strong>
        </span>
        <br />
        Created at: <strong>{details.createdAt}</strong>
        <br />
        Updated at: <strong>{details.updatedAt}</strong>
        <br />
      </DialogContentText>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography color="textSecondary">History</Typography>
        <Typography color="error">{this.props.errorWhileGetting}</Typography>
        {!this.props.errorWhileGetting && (
          <Fragment>
            <Table padding="none">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.ellipsis70}>
                    User
                  </TableCell>
                  <TableCell align="left" className={classes.ellipsis100}>
                    Key
                  </TableCell>
                  <TableCell align="left">Updated</TableCell>
                  <TableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.history.map(row => (
                  <Fragment key={row.id}>
                    {this.renderTableRow(row, this.props)}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
            <Dialog
              open={this.state.moreDetailsDialog}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">More Detail</DialogTitle>
              <DialogContent>
                {ConfigHistory.renderMoreDetails(this.state.moreDetailsItem)}
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={() => this.closeDialog()}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.restoreConfirmationDialog}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Do you really want to restore configuration?
                </DialogContentText>
              </DialogContent>
              <Typography color="error" align="center">
                {this.props.errorWhileRestoring}
              </Typography>
              <DialogActions>
                <Button color="primary" onClick={() => this.closeDialog()}>
                  Cancel
                </Button>
                <Button color="secondary" onClick={this.restore}>
                  Restore
                </Button>
                }
              </DialogActions>
            </Dialog>
          </Fragment>
        )}
      </div>
    );
  }
}

ConfigHistory.defaultProps = {
  history: [],
  loadedId: 0,
  errorWhileGetting: '',
  errorWhileRestoring: ''
};

export default withStyles(styles)(ConfigHistory);
