import React, { PureComponent, Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import apiClient from '../../api-client';

const styles = {
  container: {
    padding: 16
  },
  buttonCreate: {
    float: 'right'
  },
  ellipsis: {
    maxWidth: '130px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  formControl: {
    margin: 0,
    width: '100%',
    wrap: 'nowrap'
  }
};

class UserManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {
        id: '',
        username: '',
        password: 'password',
        role: ''
      },
      errorWhileGetting: '',
      errorWhileCreating: '',
      errorWhileDeleting: '',
      errorWhileUpdating: '',
      dialogCreate: false,
      dialogUpdate: false,
      dialogDelete: false
    };

    this.createUser = this.createUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  async createUser() {
    try {
      const user = { ...this.state.currentUser };
      if (!user.username || !user.role) {
        this.setState({
          errorWhileCreating: 'All user fields are mandatory !'
        });
      } else {
        const result = await apiClient.user.create(user);
        if (result.error || result.message) {
          throw new Error(result.error || result.message);
        }
        user.id = result.id;
        this.props.onCreate(user);
        this.closeDialog();
      }
    } catch (error) {
      this.setState({ errorWhileCreating: error.message });
      console.error(error);
    }
  }

  async deleteUser(id) {
    try {
      await apiClient.user.delete(id);
      this.props.onDelete(id);
      this.closeDialog();
    } catch (error) {
      this.setState({ errorWhileDeleting: error.message || error });
      console.log(error);
    }
  }

  async updateUser() {
    try {
      const user = { ...this.state.currentUser };
      if (!user.username || !user.role) {
        this.setState({
          errorWhileCreating: 'All user fields are mandatory !'
        });
      } else {
        const result = await apiClient.user.update(user);
        if (isEmpty(result) || result.message) {
          throw new Error(result.message || 'Oops! An error happened.');
        }
        this.props.onUpdate(this.state.currentUser);
        this.closeDialog();
      }
    } catch (error) {
      this.setState({ errorWhileUpdating: error.message || error });
      console.error(error);
    }
  }

  handleChange(event) {
    this.setState({
      currentUser: {
        ...this.state.currentUser,
        [event.target.name]: event.target.value
      }
    });
  }

  onDeleteClick(user) {
    this.setState({
      dialogDelete: true,
      currentUser: user
    });
  }

  onUpdateClick(user) {
    this.setState({
      dialogUpdate: true,
      currentUser: user
    });
  }

  closeDialog() {
    this.setState({
      errorWhileGetting: '',
      errorWhileCreating: '',
      errorWhileDeleting: '',
      errorWhileUpdating: '',
      dialogCreate: false,
      dialogUpdate: false,
      dialogDelete: false,
      currentUser: {
        id: '',
        username: '',
        password: 'password',
        role: ''
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography color="textSecondary">User Management</Typography>
        <Typography color="error">{this.state.errorWhileGetting}</Typography>
        {!this.state.errorWhileGetting && (
          <Fragment>
            <IconButton
              className={classes.buttonCreate}
              aria-label="Create"
              onClick={() => this.setState({ dialogCreate: true })}
            >
              <AddIcon />
            </IconButton>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.ellipsis}>
                    Id
                  </TableCell>
                  <TableCell align="left" className={classes.ellipsis}>
                    User Name
                  </TableCell>
                  <TableCell align="left" className={classes.ellipsis}>
                    Role
                  </TableCell>
                  <TableCell align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.users.map(row => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      padding="dense"
                      className={classes.ellipsis}
                    >
                      {row.id}
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="dense"
                      className={classes.ellipsis}
                    >
                      {row.username}
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="dense"
                      className={classes.ellipsis}
                    >
                      {row.role}
                    </TableCell>
                    <TableCell align="left" padding="none">
                      <IconButton
                        className={classes.button}
                        aria-label="Edit"
                        onClick={() => this.onUpdateClick(row)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        className={classes.button}
                        aria-label="Delete"
                        onClick={() => this.onDeleteClick(row)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}

        <Dialog
          open={this.state.dialogCreate}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New User</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              name="username"
              label="User Name"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              id="password"
              name="password"
              label="Password"
              type="Password"
              autoComplete="new-password"
              onChange={this.handleChange}
              fullWidth
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="role">Role</InputLabel>
              <Select
                value={this.state.currentUser.role}
                onChange={this.handleChange}
                autoWidth={true}
                inputProps={{
                  name: 'role',
                  id: 'role'
                }}
              >
                {this.props.roles.map(role => (
                  <MenuItem key={role.id} value={role.name}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <Typography color="error" align="center">
            {this.state.errorWhileCreating}
          </Typography>
          <DialogActions>
            <Button color="secondary" onClick={() => this.closeDialog()}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => this.createUser()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogDelete}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you really want to delete user "
              {this.state.currentUser.username}" ?
            </DialogContentText>
          </DialogContent>
          <Typography color="error" align="center">
            {this.state.errorWhileDeleting}
          </Typography>
          <DialogActions>
            <Button color="primary" onClick={() => this.closeDialog()}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={() => this.deleteUser(this.state.currentUser.id)}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogUpdate}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              name="username"
              label="User Name"
              onChange={this.handleChange}
              defaultValue={this.state.currentUser.username}
              fullWidth
            />
            <TextField
              select
              id="role"
              name="role"
              label="Role"
              margin="dense"
              fullWidth={true}
              SelectProps={{
                native: true
              }}
              defaultValue={this.state.currentUser.role}
              onChange={this.handleChange}
            >
              {this.props.roles.map(role => (
                <option key={role.name} value={role.name}>
                  {role.name}
                </option>
              ))}
            </TextField>
          </DialogContent>
          <Typography color="error" align="center">
            {this.state.errorWhileUpdating}
          </Typography>
          <DialogActions>
            <Button color="secondary" onClick={() => this.closeDialog()}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => this.updateUser()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(UserManagement);
