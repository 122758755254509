import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import apiClient from '../api-client';

const styles = {
  paper: {
    padding: 16,
    minWidth: 275,
    background: 'rgba(255,255,255,0.2)'
  },
  input: {
    marginBottom: 10,
    width: '100%'
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    Login.cleanApiKey();
    this.state = {
      username: '',
      password: 'password',
      loginDisabled: true,
      error: ''
    };

    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
  }

  async login(data) {
    try {
      const result = await apiClient.authentication.login(data);
      if (!result) {
        throw new Error('Oops! Unknown user and/or password combination');
      }
      if (result.message) {
        throw new Error(result.message);
      }

      if (result.apiKey) {
        window.localStorage.setItem('shiva', result.apiKey);
        window.localStorage.setItem('username', result.username);
        window.localStorage.setItem('role', result.role);
        window.localStorage.setItem(
          'perms',
          btoa(result.permissions.join(','))
        );
        window.location = '/';
      }
    } catch (error) {
      this.setState({
        error: error.message
      });
      console.error(error);
    }
  }

  static cleanApiKey() {
    window.localStorage.removeItem('shiva');
    window.localStorage.removeItem('perms');
    window.localStorage.removeItem('username');
    window.localStorage.removeItem('role');
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  validateInput(event) {
    return !this.state.username || !this.state.password;
  }

  onLoginClick() {
    const { username, password } = this.state;
    this.login({ username, password });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <h2>Shiva Control Panel</h2>
            <TextField
              onChange={this.handleChange}
              name="username"
              label="User Name"
              type="text"
              color="secondary"
              autoFocus={true}
              autoComplete="current-user"
              fullWidth={true}
            />
            <TextField
              onChange={this.handleChange}
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              fullWidth={true}
              margin="normal"
              value={this.state.password}
            />
            <Button
              onClick={this.onLoginClick}
              variant="contained"
              color="primary"
              disabled={this.validateInput()}
              fullWidth={true}
            >
              Login
            </Button>
            {this.state.error && (
              <Typography color="error">{this.state.error}</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Login);
