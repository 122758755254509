import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IntensityRow from './intensity.row';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';

const styles = {
  container: {
    padding: 16
  }
};

const validateMandatoryFields = ({ localHour, adDay, percentage }) =>
  localHour !== -1 && adDay && adDay.toString() && percentage;

const validateDuplicateTime = (data, props) =>
  props.data.find(item => Number(item.localHour) === Number(data.localHour));

class DayView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      missingFieldError: false,
      duplicateError: false,
      newRow: {}
    };

    this.onAddClick = this.onAddClick.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  }

  onAddClick() {
    const isValid = validateMandatoryFields(this.state.newRow);
    if (!isValid) {
      this.setState({ missingFieldError: true });
      return;
    }
    const isDuplicate = validateDuplicateTime(this.state.newRow, this.props);
    if (isDuplicate) {
      this.setState({ duplicateError: true });
      return;
    }
    this.props.onAdd(this.state.newRow);
    this.setState({ newRow: {} });
  }

  handleSnackbarClose() {
    this.setState({
      duplicateError: false,
      missingFieldError: false
    });
  }

  handleNewChange(data) {
    this.setState({ newRow: data });
  }

  render() {
    const { data } = this.props;
    const { newRow, duplicateError, missingFieldError } = this.state;

    return (
      <Grid container spacing={8}>
        {data.map(row => (
          <IntensityRow
            key={row.localHour}
            localHour={row.localHour}
            adDay={row.adDay}
            hasError={row.hasError}
            percentage={row.percentage}
            removeButton={true}
            onAddClick={this.onAddClick}
            onChange={newData => this.props.onChange(row.localHour, newData)}
            onRemoveClick={this.props.onRemove}
          />
        ))}
        <Grid item xs={12}>
          <Divider />
          <Chip
            style={{ marginTop: '20px', float: 'left' }}
            color="secondary"
            label="New Row"
            variant="outlined"
          />
        </Grid>
        <IntensityRow
          key={newRow.localHour}
          localHour={newRow.localHour}
          adDay={newRow.adDay}
          percentage={newRow.percentage}
          addButton={true}
          onChange={newData => this.handleNewChange(newData)}
          onAddClick={this.onAddClick}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={missingFieldError}
          onClose={this.handleSnackbarClose}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={
            <span id="message-id">All fields are mandatory for new row</span>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={duplicateError}
          onClose={this.handleSnackbarClose}
          autoHideDuration={3000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={
            <span id="message-id">Duplicated time slot is not allowed</span>
          }
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(DayView);
