import {
  createAction,
  createScope,
  createRequestProcessor,
  createApiCreator
} from './utils';

const createSomqueryApis = (paths, apiCreator, scopeCreator, actionCreator) =>
  paths.map(path =>
    apiCreator(scopeCreator('somquery', [path, 'configurations']), [
      'get',
      'delete',
      // prettier-ignore
      actionCreator('set', (scope, name, data, options) =>
        ['POST', scope, data, options ]),
      // prettier-ignore
      actionCreator('getByKey', (scope, name, key, options) =>
        [ 'GET', scopeCreator(scope, [], { key }), undefined, options]),
      // prettier-ignore
      actionCreator('active', (scope, name, options) =>
        [ 'GET', scopeCreator(scope, [name]), undefined, options]),
      // prettier-ignore
      actionCreator('latest', (scope, name, options) =>
        [ 'GET', scopeCreator(scope, [name]), undefined, options]),
      // prettier-ignore
      actionCreator('activate', (scope, name, id, options) =>
        [ 'PUT', scopeCreator(scope, [id, name]), undefined, options]),
      // prettier-ignore
      actionCreator('history', (scope, name, options) =>
        [ 'GET', scopeCreator(scope, [name]), undefined, options])
    ])
  );

export default ({
  authorization,
  baseUrl,
  fetch,
  defaultHeaders = {},
  defaultRequestOptions = {},
  statusHandlers = {}
}) => {
  const requestProcessor = createRequestProcessor({
    authorization,
    baseUrl,
    fetch,
    defaultHeaders,
    defaultRequestOptions,
    statusHandlers
  });
  const createApi = createApiCreator(requestProcessor);

  // prettier-ignore
  const user = createApi(
    createScope('users'),
    ['get', 'delete', 'create', 'update', 'all']
  );

  // prettier-ignore
  const permissions = createApi(
    createScope('permissions'),
    ['get', 'all']
  );

  // prettier-ignore
  const role = createApi(
    createScope('roles'),
    ['get', 'delete', 'create', 'update', 'all']
  );

  const featureToggle = createApi(createScope('feature-toggles'), [
    'all',
    'delete',
    'create',
    'update'
  ]);

  // prettier-ignore
  const cerberusUseCase = createApi(
    createScope('cerberus', ['use-cases']),
    ['get', 'delete', 'create', 'update', 'all']
  );

  // prettier-ignore
  const cerberusDashboardView = createApi(
    createScope('cerberus', ['view-dashboard']),
    ['get', 'delete', 'create', 'update', 'all']
  );

  const [
    adIntensityConfig,
    adIntensitySpotConfig,
    headerBiddingConfig
  ] = createSomqueryApis(
    ['ad-intensity', 'ad-intensity-spot', 'header-bidding'],
    createApi,
    createScope,
    createAction
  );

  // prettier-ignore
  const authentication = createApi(
    createScope(''),
    [
      createAction('login',
        (scope, name, data, options) => (
          ['POST', createScope(scope, [name]), data, options]
        )
      ),
    ]
  );

  const history = createApi(createScope('changes'), ['all']);

  // prettier-ignore
  const sitescripts = createApi(
    createScope('sitescripts'),
    [
      'get',
      createAction('overview',
        (scope, name, options) => (
          ['GET', createScope(scope, [name]), undefined, options]
        )
      ),
      createAction('ids',
        (scope, name, options) => (
          ['GET', createScope(scope, [name]), undefined, options]
        )
      ),
      createAction('all',
        (scope, name, options = {}) => (
          ['GET', createScope(scope), undefined, { asChunks: true, ...options  }]
        )
      )
    ]
  );

  // prettier-ignore
  const livestream = createApi(
    createScope('livestream'),
    [
      createAction('trigger',
        (scope, name, data, options) => (
          ['POST', createScope(scope, [name]), data, options]
        )
      ),
      createAction('history',
        (scope, name, options) => (
          ['GET', createScope(scope, [name]), undefined, options]
        )
      ),
      createAction('channelIds',
        (scope, name, options) => (
          ['GET', createScope(scope, ['channels']), undefined, options]
        )
      )
    ]
  );

  // prettier-ignore
  const mappingFiles = createApi(
  createScope('somquery'),
  [
    createAction('reload-mapping-files',
      (scope, name, options) => (
        ['POST', createScope(scope, [name]), undefined, options]
      )
    )
  ]
);

  return {
    user,
    role,
    cerberusUseCase,
    cerberusDashboardView,
    adIntensityConfig,
    adIntensitySpotConfig,
    authentication,
    history,
    permissions,
    headerBiddingConfig,
    featureToggle,
    sitescripts,
    livestream,
    mappingFiles
  };
};
