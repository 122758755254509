import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MappingFilesIcon from '@material-ui/icons/Replay';
import apiClient from '../api-client';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {
  container: {
    padding: 16
  },
  paper: {
    background: 'rgba(255,255,255,0.2)',
    height: '100%'
  },
  grid: {
    marginBottom: 16
  }
};

class ReloadMappingFilesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSaveDialog: false
    };
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  handleSaveClick() {
    this.setState({ showSaveDialog: true });
  }

  handleCloseDialog() {
    this.setState({
      showSaveDialog: false
    });
  }

  async clickHandler() {
    try {
      const result = await apiClient.mappingFiles['reload-mapping-files']();
      this.handleCloseDialog();
      if (result.message !== 'Reloading mapping files...') {
        throw new Error(result.message);
      }
      console.log(
        'Successfully triggered Shiva API server to trigger SomQuery to reload mapping files.'
      );
    } catch (error) {
      console.log('error', error);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid
          container
          spacing={8}
          alignItems="stretch"
          className={classes.grid}
        >
          <Grid item lg={4} md={12}>
            <Paper className={classes.paper}>
              <div className={classes.container}>
                <Typography color="textSecondary">
                  Somquery Mapping File Update Trigger
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  style={{ margin: '30px' }}
                  onClick={async () => {
                    this.handleSaveClick();
                  }}
                >
                  <MappingFilesIcon />
                  Reload Mapping Files
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.showSaveDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reload</DialogTitle>
          <DialogContent>
            <Typography align="center">
              Do you confirm to reload mapping files ?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => this.handleCloseDialog()}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={async () => {
                await this.clickHandler();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ReloadMappingFilesPage);
