import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import isEmpty from 'lodash/isEmpty';
import apiClient from '../api-client';

class ChangePassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: 'password',
      newPassword: 'password',
      newPassword2: '',
      error: ''
    };

    this.handlePasswordUpdate = this.handlePasswordUpdate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static verifyPassword({ newPassword, newPassword2 }) {
    return newPassword !== newPassword2 ? 'New passwords do not match!' : '';
  }

  handleChange(event) {
    const newState = {
      ...this.state,
      [event.target.name]: event.target.value
    };
    const error = ChangePassword.verifyPassword(newState);
    this.setState({ ...newState, error });
  }

  handleClose() {
    this.setState({
      currentPassword: 'password',
      newPassword: 'password',
      newPassword2: '',
      error: ''
    });
    this.props.onClose();
  }

  async handlePasswordUpdate() {
    if (this.state.error) return;

    const { newPassword, currentPassword } = this.state;
    try {
      const result = await apiClient.user.update({
        newPassword,
        currentPassword
      });
      if (isEmpty(result) || result.message) {
        throw new Error(result.message || 'Oops! An error happened.');
      }
      this.handleClose();
      window.localStorage.setItem('shiva', result.apiKey);
    } catch (error) {
      this.setState({ error: error.message || error });
    }
  }

  render() {
    const { currentPassword, newPassword, newPassword2, error } = this.state;
    const enableSave = currentPassword && newPassword && newPassword2;
    const commonProps = {
      type: 'Password',
      autoComplete: 'new-password',
      fullWidth: true
    };
    return (
      <Dialog open={this.props.show} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="currentPassword"
            name="currentPassword"
            label="Current Password"
            onChange={this.handleChange}
            {...commonProps}
          />
          <TextField
            id="newPassword"
            name="newPassword"
            label="New Password"
            margin="dense"
            onChange={this.handleChange}
            {...commonProps}
          ></TextField>
          <TextField
            id="newPassword2"
            name="newPassword2"
            label="Verify New Password"
            margin="dense"
            onChange={this.handleChange}
            {...commonProps}
          ></TextField>
        </DialogContent>
        <Typography color="error" align="center">
          {enableSave && error}
        </Typography>
        <DialogActions>
          <Button color="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this.handlePasswordUpdate}
            disabled={!enableSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ChangePassword;
