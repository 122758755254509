import React, { useReducer, useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import FilterIcon from '@material-ui/icons/FilterList';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import cn from 'classnames';

import './livestream-history-panel.css';

const styles = {
  container: {
    padding: 16,
    position: 'relative'
  },
  panelTitle: {
    marginBottom: 40
  },
  filterControls: {
    display: 'flex',
    position: 'absolute',
    right: 25,
    top: 15
  },
  ellipsis100: {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  ellipsis70: {
    maxWidth: '70px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  dialogCloseButton: {
    position: 'absolute',
    right: 20,
    top: 16
  }
};

const getColorByStatus = status => {
  if (status >= 200 && status <= 299) return 'green';
  if (status >= 300 && status <= 399) return 'yellow';
  if (status >= 400 && status <= 599) return 'red';
};

const EventDetails = ({ classes, event }) => (
  <TableRow>
    <TableCell align="center" className={classes.ellipsis70}>
      <Tooltip title={event.statusText} placement="right">
        <Chip
          size="small"
          style={{ background: getColorByStatus(event.status) }}
          label={event.status}
        />
      </Tooltip>
    </TableCell>
    <TableCell align="center" className={classes.ellipsis100}>
      {moment(event.targetTime).format('YYYY-MM-DD HH:mm:ss:ms Z')}
    </TableCell>
    <TableCell align="center" className={classes.ellipsis100}>
      {event.channelId}
    </TableCell>
    <TableCell align="center" className={classes.ellipsis100}>
      {event.payload.slots.midroll.count}
    </TableCell>
  </TableRow>
);

const LivestreamTriggerHistory = ({ classes, history, channelIds }) => {
  const defaultState = {
    showFilterDialog: false,
    filters: {
      status: null,
      channelId: null,
      from: moment().subtract(1, 'year'),
      to: moment().add(1, 'day'),
      clear: true
    }
  };

  const [state, setState] = useReducer(
    (curState, newState) => ({ ...curState, ...newState }),
    defaultState
  );

  const filterHistory = useCallback(
    events =>
      events.filter(event => {
        const matches = [];

        if (state.filters.status) {
          matches.push(event.status === Number(state.filters.status));
        }

        if (state.filters.channelId) {
          matches.push(event.channelId === state.filters.channelId);
        }

        if (state.filters.from && state.filters.to) {
          matches.push(
            moment(event.targetTime).isBetween(
              state.filters.from,
              state.filters.to
            )
          );
        }

        return !matches.includes(false);
      }),
    [state.filters]
  );

  const sortedFilteredHistory = useMemo(
    () =>
      filterHistory(history).sort(
        (a, b) => new Date(b.targetTime) - new Date(a.targetTime)
      ),
    [filterHistory, history]
  );

  const showFilterDialog = () =>
    setState({
      showFilterDialog: true
    });

  const hideFilterDialog = () =>
    setState({
      showFilterDialog: false
    });

  const resetFilters = () =>
    setState({
      filters: defaultState.filters
    });

  const onChangeFilterInput = ({ target: { name, value } }) => {
    let filterValue;

    switch (name) {
      case 'channelId':
        filterValue = value === 'all' ? null : value;
        break;
      case 'from':
      case 'to':
        filterValue = moment(value || state.filters[name]);
        break;
      default:
        filterValue = value;
    }

    setState({
      filters: {
        ...state.filters,
        clear: false,
        [name]: filterValue
      }
    });
  };

  return (
    <div className={cn('LivestreamHistory', classes.container)}>
      <div className={classes.panelTitle}>
        <Typography color="textSecondary">History</Typography>
      </div>
      <div className={classes.filterControls}>
        <Tooltip title="clear filters">
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            style={{
              display: state.filters.clear ? 'none' : 'inherit',
              marginRight: 10
            }}
            onClick={resetFilters}
          >
            <DeleteIcon fontSize="small" />
          </Fab>
        </Tooltip>
        <Tooltip title="Filter">
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            onClick={showFilterDialog}
          >
            <FilterIcon />
          </Fab>
        </Tooltip>
      </div>
      <Table padding="none" className="LivestreamHistory__table">
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.ellipsis70}>
              Http Status
            </TableCell>
            <TableCell align="center" className={classes.ellipsis100}>
              Time
            </TableCell>
            <TableCell align="center">Channel ID</TableCell>
            <TableCell align="center">Midroll Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedFilteredHistory.map(event => (
            <EventDetails key={event.id} event={event} classes={classes} />
          ))}
        </TableBody>
      </Table>
      <Dialog open={state.showFilterDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Filter events history
          <IconButton
            aria-label="close"
            className={classes.dialogCloseButton}
            onClick={hideFilterDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            id="status"
            name="status"
            label="Http Status"
            margin="normal"
            defaultValue={state.filters.status}
            onChange={onChangeFilterInput}
            fullWidth={true}
          />
          <TextField
            id="from"
            name="from"
            label="From Time"
            type="datetime-local"
            onChange={onChangeFilterInput}
            defaultValue={moment(state.filters.from)
              .format('YYYY-MM-DDTHH:mm')
              .toString()}
          />
          <TextField
            id="to"
            name="to"
            label="To Time"
            type="datetime-local"
            onChange={onChangeFilterInput}
            style={{ float: 'right' }}
            defaultValue={moment(state.filters.to)
              .format('YYYY-MM-DDTHH:mm')
              .toString()}
          />
          <TextField
            id="channelId"
            name="channelId"
            label="Channel Id"
            margin="normal"
            onChange={onChangeFilterInput}
            fullWidth={true}
            value={state.filters.channelId || 'all'}
            select
          >
            {['all', ...channelIds].map(channel => (
              <MenuItem key={channel} value={channel}>
                {channel}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(LivestreamTriggerHistory);
