const getUTCHour = ({ localHour }) => {
  const offset = new Date().getTimezoneOffset() / 60;
  let utcHour = Number(localHour) + offset;
  if (utcHour === -1) utcHour = 23;
  if (utcHour === -2) utcHour = 22;
  return utcHour;
};

const getUTCDay = ({ localHour, hour, localDay }) => {
  const offset = new Date().getTimezoneOffset() / 60;
  return Math.abs(Number(localHour) - hour) > Math.abs(offset)
    ? localDay - 1
    : localDay;
};

const getUTCTime = localTime => {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  return new Date(localTime).getTime() + offset;
};

const getDayData = (index, { recurring }) =>
  recurring.filter(data => data.localDay === index);

const onGlobalChange = (props, key, data) => {
  data.hour = getUTCHour(data);
  const global = [...props.adIntensityData.global];
  const index = global.findIndex(item => key === item.localHour);
  global[index] = data;
  props.onChange({ ...props.adIntensityData, global });
};

const onRecurringChange = (props, key, newData) => {
  const recurring = [...props.adIntensityData.recurring];
  const localDayIndex = props.tab.index;
  const index = recurring.findIndex(
    item => key === item.localHour && localDayIndex === item.localDay
  );
  newData.localDay = props.tab.index;
  newData.hour = getUTCHour(newData);
  newData.day = getUTCDay(newData);
  recurring[index] = newData;
  props.onChange({ ...props.adIntensityData, recurring });
};

const onRecurringAdd = (props, newData) => {
  newData.localDay = props.tab.index;
  newData.hour = getUTCHour(newData);
  newData.day = getUTCDay(newData);
  const recurring = [...props.adIntensityData.recurring, newData];
  props.onChange({ ...props.adIntensityData, recurring });
};

const onRecurringRemove = (props, localHour) => {
  const localDayIndex = props.tab.index;
  const recurring = [...props.adIntensityData.recurring].filter(
    item => item.localDay !== localDayIndex || item.localHour !== localHour
  );
  props.onChange({ ...props.adIntensityData, recurring });
};

const onTimeboxChange = (props, key, newData) => {
  newData.start = getUTCTime(newData.localTimeStart);
  newData.end = getUTCTime(newData.localTimeEnd);
  const timebox = [...props.adIntensityData.timebox];
  timebox[key] = newData;
  props.onChange({ ...props.adIntensityData, timebox });
};

const onTimeboxAdd = (props, newData) => {
  newData.start = getUTCTime(newData.localTimeStart);
  newData.end = getUTCTime(newData.localTimeEnd);
  const timebox = [...props.adIntensityData.timebox, newData];
  props.onChange({ ...props.adIntensityData, timebox });
};

const onTimeboxRemove = (props, key) => {
  const timebox = props.adIntensityData.timebox.filter(
    item => item.localTimeStart !== key
  );
  props.onChange({ ...props.adIntensityData, timebox });
};

const handleConfigActiveChange = (props, { target }) => {
  const active = target.checked;
  props.onChange({ ...props.adIntensityData, active });
};

export {
  getUTCHour,
  getDayData,
  onGlobalChange,
  onRecurringChange,
  onRecurringAdd,
  onRecurringRemove,
  onTimeboxChange,
  onTimeboxAdd,
  onTimeboxRemove,
  handleConfigActiveChange
};
