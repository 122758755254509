import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import JSONTree from 'react-json-tree';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import { ClapSpinner } from 'react-spinners-kit';

import apiClient from '../../api-client';

const SITESCRIPTS_STORAGE_KEY = 'shiva:sitescripts';

const loadFromLocalStorage = (key, defaultValue) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  } catch (_) {
    return defaultValue;
  }
};

const persistInLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const loadSitescripts = (key, defaultValue) =>
  loadFromLocalStorage(`${SITESCRIPTS_STORAGE_KEY}:${key}`, defaultValue);

const persistSitescripts = (key, value) =>
  persistInLocalStorage(`${SITESCRIPTS_STORAGE_KEY}:${key}`, value);

const defaultStyles = {
  subtitle: {
    fontSize: '1.2em',
    display: 'inline-block'
  }
};

const searchBarStyles = {
  option: (styles, state) => ({
    ...styles,
    borderBottom: '1px dotted pink',
    color: 'blue',
    padding: 15,
    backgroundColor: state.isFocused ? 'pink' : 'rgba(253,161,255,0.2)'
  }),
  control: styles => ({
    ...styles,
    color: 'green',
    margin: '20px auto 20px auto',
    maxWidth: 500,
    backgroundColor: 'rgba(253,161,255,0.2)'
  }),
  singleValue: styles => ({
    ...styles,
    color: '#fff'
  })
};

const tableTheme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
  tree: {
    display: 'block',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    padding: 10,
    fontSize: '1.2em',
    backgroundColor: 'rgba(255,255,255,0.2)'
  },
  valueLabel: {
    textDecoration: 'underline'
  },
  nestedNodeLabel: ({ style }, nodeType, expanded) => ({
    style: {
      ...style,
      textTransform: expanded ? 'uppercase' : style.textTransform
    }
  })
};

class SingleSitescripts extends Component {
  constructor(props) {
    super(props);
    this.state = SingleSitescripts.initState(props);
    this.abortController = new AbortController();
  }

  static initState({ match: { params } }) {
    const ids = loadSitescripts('ids', []);
    const cache = loadSitescripts('configs', {});
    const sitescript = cache[params.id || ids[0]] || {};
    return {
      ids,
      cache,
      sitescript
    };
  }

  async componentDidMount() {
    try {
      const { signal } = this.abortController;
      const ids = await apiClient.sitescripts.ids({ signal });
      this.setState(() => ({ ids }));
      persistSitescripts('ids', ids);

      const first = this.state.sitescript.id || ids[0];
      const sitescript = await apiClient.sitescripts.get(first, { signal });
      this.saveSitescript(sitescript);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  }

  async shouldComponentUpdate(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const sitescript = await apiClient.sitescripts.get(
        nextProps.match.params.id,
        { signal: this.abortController.signal }
      );
      this.saveSitescript(sitescript);
    }
    return true;
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  saveSitescript(sitescript) {
    // add to the cache for later optimistic updates
    const updatedCache = {
      ...this.state.cache,
      [sitescript.id]: sitescript
    };

    this.setState(() => ({
      cache: updatedCache,
      sitescript
    }));

    persistSitescripts('configs', updatedCache);
  }

  onSitescriptChange({ value: id }) {
    if (id !== this.props.match.params.id) {
      // optimistically update ui with sitescript from the cache
      const { [id]: sitescript = {} } = this.state.cache;
      this.setState(() => ({ sitescript }));
      this.props.history.push(`/sitescripts/single/${id}`);
    }
  }

  static shouldExpand(key, data, level) {
    return level < 3;
  }

  static renderSpinner() {
    return (
      <div
        style={{
          height: 'calc(100vh/3)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ClapSpinner loading />
      </div>
    );
  }

  static renderSitescriptConfig({ classes, sitescript }) {
    const { id, parsedScript } = sitescript;
    return (
      <div>
        <Typography className={classes.subtitle}>Sitescript: {id}</Typography>
        <div style={{ display: 'flex', width: '100%' }}>
          {Object.entries(parsedScript).map(([key, data]) => (
            <div key={key} style={{ width: '100%', padding: '10px' }}>
              <Typography className={classes.subtitle}>
                {key.toUpperCase()}
              </Typography>
              <JSONTree
                hideRoot={true}
                data={data}
                theme={tableTheme}
                invertTheme={false}
                shouldExpandNode={SingleSitescripts.shouldExpand}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { sitescript } = this.state;
    const loading = !sitescript.id;

    return (
      <Fragment>
        <Select
          name={sitescript.id}
          styles={searchBarStyles}
          onChange={this.onSitescriptChange.bind(this)}
          placeholder="select or search a sitescript to view its configuration"
          options={this.state.ids.map(id => ({
            label: id,
            value: id
          }))}
        />
        {loading
          ? SingleSitescripts.renderSpinner()
          : SingleSitescripts.renderSitescriptConfig({ classes, sitescript })}
      </Fragment>
    );
  }
}

export default withStyles(defaultStyles)(SingleSitescripts);
