import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import SwipeButton from './swipe-button';

const styles = {
  container: {
    padding: 16
  },
  buttonCreate: {
    float: 'right'
  },
  inputMargin: {
    margin: 4
  }
};

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const formatTime = time =>
  moment(time)
    .milliseconds(0)
    .format('DD-MMMM-YYYY hh:mm:ss Z');

const LivestreamTrigger = ({
  classes,
  time,
  channelIds,
  onChannelIdselect,
  onMidrollCountChange,
  onTrigger,
  isTriggerDisabled,
  errorOnSave
}) => {
  return (
    <div className={classes.container}>
      <Typography color="textSecondary">Live Stream Trigger</Typography>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <TextField
            select
            name="hour"
            label="Channel Id"
            margin="dense"
            variant="outlined"
            fullWidth={true}
            SelectProps={{
              native: true
            }}
            onChange={onChannelIdselect}
          >
            <option key={-1} value={''}></option>
            {channelIds.map(channelId => (
              <option key={channelId} value={channelId}>
                {channelId}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            id="time"
            label={`Time - Your current zone is '${timezone}'`}
            value={formatTime(time)}
            margin="normal"
            variant="outlined"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="midrollCount"
            label="Midroll count"
            defaultValue={1}
            margin="normal"
            variant="outlined"
            fullWidth={true}
            type="number"
            onChange={onMidrollCountChange}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'block' }}>
            <div style={{ display: 'inline-block' }}>
              <SwipeButton onChange={onTrigger} disabled={isTriggerDisabled} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography color="error" align="center">
            {errorOnSave}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(LivestreamTrigger);
