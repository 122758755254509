import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ToggleFormModal from './toggle-form-modal';

const styles = {
  container: {
    padding: 20
  },
  referencedCell: {
    color: '#12af83',
    'font-weight': '600'
  }
};

const TogglePanel = ({ classes, ...props }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedToggle, setSelectedToggle] = useState(null);

  const handleEnableChange = (toggle, event) => {
    props.onUpdate({ ...toggle, enable: event.target.checked });
  };

  const updateToggle = async toggle => {
    await props.onUpdate(toggle);
    handleClose();
  };

  const deleteToggle = async toggle => {
    if (toggle.state === 'referenced') {
      alert('toggle is still being referenced (state)');
      return;
    }
    await props.onDelete(toggle);
    handleClose();
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedToggle(null);
  };

  const editToggle = toggle => {
    setOpenModal(true);
    setSelectedToggle(toggle);
  };

  const buttonConfig = [
    {
      title: 'Save',
      action: updateToggle,
      type: 'primary'
    },
    {
      title: 'Cancel',
      action: handleClose
    },
    {
      title: 'Delete',
      action: deleteToggle
    }
  ];

  return (
    <div className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sortDirection="asc">CaseNo</TableCell>
            <TableCell>Create Date</TableCell>
            <TableCell>Context</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Enable</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.toggles &&
            props.toggles.map(toggle => (
              <TableRow key={toggle.id}>
                <TableCell padding="dense" component="th" scope="row">
                  {toggle.caseNo}
                </TableCell>
                <TableCell align="left">{toggle.createdAt}</TableCell>
                <TableCell align="left">{toggle.context}</TableCell>
                <TableCell align="left">{toggle.description}</TableCell>
                <TableCell align="left">
                  {' '}
                  <span
                    className={
                      toggle.state === 'referenced'
                        ? classes.referencedCell
                        : ''
                    }
                  >
                    {toggle.state}
                  </span>
                </TableCell>
                <TableCell align="left">
                  <Switch
                    disabled={toggle.state === 'obsolete'}
                    name="enabled"
                    onChange={event => handleEnableChange(toggle, event)}
                    checked={toggle.enable}
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => editToggle(toggle)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <ToggleFormModal
        open={openModal}
        title="Edit Toggle"
        selectedToggle={selectedToggle}
        onClose={handleClose}
        buttons={buttonConfig}
      />
    </div>
  );
};

export default withStyles(styles)(TogglePanel);
