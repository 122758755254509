import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LivestreamTriggerHistory from '../components/livestream-trigger/livestream-history-panel';
import LivestreamTrigger from '../components/livestream-trigger/livestream-settings-panel';
import apiClient from '../api-client';

const styles = {
  paper: {
    background: 'rgba(255,255,255,0.2)',
    height: '100%'
  },
  grid: {
    marginBottom: 16
  }
};

class LivesStreamTriggerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channelIds: [],
      channelId: '',
      midrollCount: 1,
      time: moment(),
      isTriggerDisabled: true,
      history: []
    };

    this.timeIntervalId = null;
    this.abortController = new AbortController();
    this.onTrigger = this.onTrigger.bind(this);
    this.onChannelIdselect = this.onChannelIdselect.bind(this);
    this.onMidrollCountChange = this.onMidrollCountChange.bind(this);
  }

  componentDidMount() {
    this.startTimeTicker();
    this.fetchChannelIds();
    this.fetchTriggerHistory();
  }

  startTimeTicker() {
    this.timeIntervalId = setInterval(() => {
      this.setState(() => ({
        time: moment()
      }));
    }, 1000);
  }

  fetchChannelIds() {
    apiClient.livestream
      .channelIds({
        signal: this.abortController.signal
      })
      .then(channelIds =>
        this.setState(() => ({
          channelIds
        }))
      )
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      });
  }

  fetchTriggerHistory() {
    apiClient.livestream
      .history({
        signal: this.abortController.signal
      })
      .then(history =>
        this.setState(() => ({
          history
        }))
      )
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      });
  }

  async onTrigger(ready) {
    if (ready) {
      try {
        const data = {
          channelId: this.state.channelId,
          targetTime: this.state.time.toISOString(),
          targetCount: this.state.midrollCount
        };
        const result = await apiClient.livestream.trigger(data);
        if (result.message) {
          throw new Error(result.message);
        }
      } catch (error) {
        this.setState({ saveError: error.message });
      } finally {
        this.fetchTriggerHistory();
      }
    }
  }

  onChannelIdselect({ target: { value } }) {
    this.setState(() => ({
      channelId: value,
      isTriggerDisabled: !value
    }));
  }

  onMidrollCountChange({ target: { value } }) {
    this.setState(() => ({
      midrollCount: value
    }));
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeIntervalId);
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid
          container
          spacing={8}
          alignItems="stretch"
          className={classes.grid}
        >
          <Grid item lg={4} md={12}>
            <Paper className={classes.paper}>
              <LivestreamTrigger
                time={this.state.time}
                channelIds={this.state.channelIds}
                onChannelIdselect={this.onChannelIdselect}
                onMidrollCountChange={this.onMidrollCountChange}
                errorOnSave={this.state.saveError}
                isTriggerDisabled={this.state.isTriggerDisabled}
                onTrigger={this.onTrigger}
              />
            </Paper>
          </Grid>
          <Grid item lg={8} md={12} xs={12}>
            <Paper className={classes.paper}>
              <LivestreamTriggerHistory
                history={this.state.history}
                channelIds={this.state.channelIds}
              />
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(LivesStreamTriggerPage);
