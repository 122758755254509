import React, { useReducer } from 'react';
import PlayArrow from '@material-ui/icons/PlayArrow';
import { StageSpinner as Spinner } from 'react-spinners-kit';
import cn from 'classnames';

import './swipe-button.css';

const DRAG_POSITION_THRESHOLD = 150;

const SwipeButton = ({ disabled, onChange }) => {
  const defaultState = {
    isDragging: false,
    initialPosition: 0,
    isDragged: false,
    swipePosition: 0,
    isTriggering: false,
    withTransition: false
  };

  const [state, setState] = useReducer(
    (curState, newState) => ({ ...curState, ...newState }),
    defaultState
  );

  const resetDrag = () => {
    // all except 'withTransition' node to allow reverse transition via CSS
    const { withTransition, ...nextState } = defaultState;
    setState(nextState);
  };

  const onTouchStart = ev => {
    if (disabled || state.isTriggering) {
      return;
    }
    ev.preventDefault();
    state.isDragging = true;
    setState({ initialPosition: ev.touches[0].pageX });
  };

  const onDragStart = ({ pageX, dataTransfer }) => {
    setState({ initialPosition: pageX });
    state.isDragging = true;
    dataTransfer.setData('text/plain', 'swipe'); // Firefox issue

    // Add empty ghost image
    const img = document.createElement('img');
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    dataTransfer.setDragImage(img, 100, 100);
  };

  const getSwipePosition = dragPosition => {
    if (dragPosition < 0) return 0;
    if (dragPosition > 200) return 200;
    return dragPosition;
  };

  const onDragOver = ev => {
    if (!state.isDragging) {
      return;
    }

    let position = ev.pageX;
    if (ev.type === 'touchmove') {
      position = ev.changedTouches[0].pageX;
    }

    if (position > 0) {
      const dragPosition = position - state.initialPosition;
      const swipePosition = getSwipePosition(dragPosition);
      setState({
        withTransition: false,
        isDragged: swipePosition > DRAG_POSITION_THRESHOLD,
        swipePosition
      });
    }
  };

  const onDragEnd = ev => {
    if (!state.isDragging) {
      return;
    }

    let finalPosition = ev.pageX;
    if (ev.type === 'touchend') {
      finalPosition = ev.changedTouches[0].pageX;
      ev.preventDefault();
    }

    const dragPosition = finalPosition - state.initialPosition;
    const dragged = dragPosition > DRAG_POSITION_THRESHOLD;
    setState({
      withTransition: true,
      isDragged: dragged,
      isTriggering: dragged,
      swipePosition: dragged ? 200 : 0
    });
    onChange(dragged).then(resetDrag);
  };

  return (
    <div
      className={cn('SwipeButton', {
        'SwipeButton--disabled': disabled,
        'SwipeButton--triggering': state.isTriggering
      })}
      onDragOver={onDragOver}
      onTouchMove={onDragOver}
    >
      <div
        draggable={!disabled && !state.isTriggering}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onTouchStart={onTouchStart}
        onTouchEnd={onDragEnd}
        className={cn('SwipeButton__draggable-layer', {
          'SwipeButton__draggable-layer--with-transition': state.withTransition
        })}
        style={{ '--swipe-position': `${state.swipePosition}px` }}
      >
        <div
          className={cn('SwipeButton__text', 'SwipeButton__layer-text', {
            'SwipeButton__layer-text--hide': !state.isDragged
          })}
        >
          {state.isTriggering ? 'Triggering...' : 'Release'}
        </div>
        <div
          className={cn('SwipeButton__knob', {
            SwipeButton__knob: disabled
          })}
        >
          {state.isTriggering ? (
            <Spinner loading size={28} />
          ) : (
            <PlayArrow style={{ width: 60, height: 60 }} />
          )}
        </div>
      </div>
      <div className="SwipeButton__text SwipeButton__outer-text">Swipe</div>
    </div>
  );
};

export default SwipeButton;
